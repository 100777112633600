import * as Yup from "yup";

export const addMinimumStaySchema = Yup.object().shape({
  start_date: Yup.string().required("start date is required"),
  end_date: Yup.string().required("end date is required"),
  no_of_days: Yup.number().required("no of days is required"),
  category_1: Yup.number().required("price is required for this unit type"),
  category_2: Yup.number().required("price is required for this unit type"),
  category_3: Yup.number().required("price is required for this unit type"),
  category_4: Yup.number().required("price is required for this unit type"),
  category_5: Yup.number().required("price is required for this unit type"),
});
