import axios from "axios";

export const getChatbotApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/chatbot`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};

export const postChatbotApi = async (values, token) => {
  return await axios.post(`${process.env.REACT_APP_BASE_URL}/chatbot`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const putChatbotApi = async (id, values, token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/chatbot/${id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteChatbotApi = async (id, token) => {
  return await axios.delete(`${process.env.REACT_APP_BASE_URL}/chatbot/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSingleChatbotApi = async (id, token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/chatbot/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};

export const getSingleChatbotDropdownApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/chatbot/dropdown`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};
