/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Actions from "../../common/ActionsButtons/Actions";
import { useSelector } from "react-redux";
import CustomTable from "../../common/CustomTable/CustomTable";
import {
  deleteAnimitesApi,
  getAnimitesApi,
  preferenceSetAPI,
} from "../../service/AnimitesService";
import Modal from "react-responsive-modal";
import AddAnimites from "./AddAnimites";
import ViewAnimites from "./ViewAnimites";
import EditAnimites from "./EditAnimites";
import Swal from "sweetalert2";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";

const Animites = () => {
  const [animitesList, setAnimitesList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const getAllUnitDetails = async () => {
    getAnimitesApi(profileData.token)
      .then((res) => {
        setAnimitesList(res?.data?.result);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    async function fetchData() {
      await getAllUnitDetails();
    }
    fetchData();
  }, [searchText]);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Image",
      selector: (row) => (
        <img
          src={row?.image[0]}
          width={50}
          class="image-block imgSvg"
          alt="img not show"
        />
      ),
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 5,
      name: "Actions",
      selector: (row) => (
        <Actions
          row={row}
          viewopenModal={viewopenModal}
          edittopenModal={editModel}
          deleteRecord={deleteRecord}
          moveDown={moveDown}
          moveUpward={moveUpward}
          arrange={true}
        />
      ),
      sortable: true,
      right: "true",
      reorder: true,
    },
  ];

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const viewopenModal = () => {
    setViewOpen(true);
  };
  const viewcloseModal = () => {
    setViewOpen(false);
  };
  const editModel = () => {
    setEditOpen(true);
  };
  const closeEditModel = () => {
    setEditOpen(false);
  };
  const moveUpward = (id) => {
    const index = animitesList.findIndex((item) => item.id === id);
    if (index > 0) {
      const newList = [...animitesList];
      [newList[index - 1], newList[index]] = [
        newList[index],
        newList[index - 1],
      ];
      setAnimitesList(newList);
    }
  };

  const moveDown = (id) => {
    const index = animitesList.findIndex((item) => item.id === id);
    if (index < animitesList.length - 1) {
      const newList = [...animitesList];
      [newList[index + 1], newList[index]] = [
        newList[index],
        newList[index + 1],
      ];
      setAnimitesList(newList);
    }
  };

  const deleteRecord = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAnimitesApi(id, profileData.token)
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: res?.data?.msg,
              icon: "success",
            });
            getAllUnitDetails();
          })
          .catch((error) => {
            ErrorAlert({ title: error?.response?.data?.error });
          });
      }
    });
  };

  const saveOrder = async () => {
    const payload = animitesList.map((item, idx) => {
      return { id: item.id, preference: idx + 1 };
    });
    preferenceSetAPI(payload, profileData.token)
      .then((res) => {
        Swal.fire({
          title: "Updated!",
          text: res?.data?.msg,
          icon: "success",
        });
        getAllUnitDetails();
      })
      .catch((error) => {
        ErrorAlert({ title: error?.response?.data?.error });
      });
  };

  return (
    <div className="content-wrapper">
      <div className="m-4">
        {animitesList && (
          <CustomTable
            columns={columns}
            data={animitesList}
            setSearchText={setSearchText}
            searchText={searchText}
            openModal={openModal}
            saveOrder={saveOrder}
          />
        )}
      </div>

      <Modal open={open} onClose={closeModal} center>
        <h2 className="ms-5">Add Amenity</h2>
        <AddAnimites
          closeModal={closeModal}
          getAllUnitDetails={getAllUnitDetails}
        />
      </Modal>

      <Modal
        open={viewOpen}
        onClose={viewcloseModal}
        center
        styles={{ width: "100px" }}
      >
        <h2 className="ms-5">View Amenity</h2>
        <ViewAnimites closeModal={viewcloseModal} />
      </Modal>

      <Modal open={editOpen} onClose={closeEditModel} center>
        <h2 className="ms-5">Edit Amenity</h2>
        <EditAnimites
          closeModal={closeEditModel}
          getAllUnitDetails={getAllUnitDetails}
        />
      </Modal>
    </div>
  );
};

export default Animites;
