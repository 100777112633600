import React, { useState } from "react";
import { useSelector } from "react-redux";
import { postMinimumStayApi } from "../../service/MinimumStayService";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addMinimumStaySchema } from "../../schema/minimumStaySchema";

const AddMinimumStay = ({ closeModal, getAllMinimumStay }) => {
  const initialValues = {
    start_date: new Date(),
    end_date: new Date(),
    no_of_days: "",
    category_1: "",
    category_2: "",
    category_3: "",
    category_4: "",
    category_5: "",
  };
  const { profileData } = useSelector((state) => state.profileReducer);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const res = await postMinimumStayApi(values, profileData.token);
      getAllMinimumStay();
      closeModal();
      SuccessAlert({ title: res.data.msg });
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={addMinimumStaySchema}
          onSubmit={(values) => {
            onFinish(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <div className="d-flex ms-5">
              <form className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Start Date</p>
                    </div>
                    <DatePicker
                      name="start_date"
                      className="input-box mt-1"
                      placeholder="Start Date"
                      onChange={(date) => setFieldValue("start_date", date)}
                      selected={values?.start_date}
                    />
                    {errors.start_date && touched.start_date && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.start_date}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>End Date</p>
                    </div>
                    <DatePicker
                      name="end_date"
                      className="input-box mt-1"
                      placeholder="End Date"
                      onChange={(date) => setFieldValue("end_date", date)}
                      selected={values?.end_date}
                    />
                    {errors.end_date && touched.end_date && (
                      <p className="text-danger mt-2 mb-3">{errors.end_date}</p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>No Of Days</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="No Of Days"
                      name="no_of_days"
                      onChange={handleChange}
                      value={values.no_of_days}
                    />
                    {errors.no_of_days && touched.no_of_days && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.no_of_days}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>1 Bed / 1 Bath</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="1 Bed / 1 Bath"
                      name="category_1"
                      onChange={handleChange}
                      value={values.category_1}
                    />
                    {errors.category_1 && touched.category_1 && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.category_1}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>2 Bed / 2 Bath</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="2 Bed / 2 Bath"
                      name="category_2"
                      onChange={handleChange}
                      value={values.category_2}
                    />
                    {errors.category_2 && touched.category_2 && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.category_2}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>3 Bed / 3 Bath</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="3 Bed / 3 Bath"
                      name="category_3"
                      onChange={handleChange}
                      value={values.category_3}
                    />
                    {errors.category_3 && touched.category_3 && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.category_3}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Deluxe 1 Bed / 2 Bath / Home Office</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="Deluxe 1 Bed / 2 Bath / Home Office"
                      name="category_4"
                      onChange={handleChange}
                      value={values.category_4}
                    />
                    {errors.category_4 && touched.category_4 && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.category_4}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Deluxe 2 Bed / 3 Bath / Home Office</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="Deluxe 2 Bed / 3 Bath / Home Office"
                      name="category_5"
                      onChange={handleChange}
                      value={values.category_5}
                    />
                    {errors.category_5 && touched.category_5 && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.category_5}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger ms-3"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddMinimumStay;
