export const setunitDetailsData = (data) => {
    return {
      type: "SETUNITDETAILS",
      payload: data,
    };
  };

  export const setId = (data) => {
    return {
      type: "SETID",
      payload: data,
    };
  };

  export const setisEdit = (data) => {
    return {
      type: "SETISEDIT",
      payload: data,
    };
  };