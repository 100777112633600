/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getSingleChatbotDropdownApi,
  postChatbotApi,
} from "../../service/ChatbotService";
import { Field, Formik } from "formik";
import Select from "react-select";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import { addQuestionSchema } from "../../schema/chatbotSchema";

const AddChatbot = ({ closeModal, getAllChatbot }) => {
  const initialValues = {
    question: "",
    answer: "",
    relatedQuestions: [],
    link: "",
    at_display: true,
  };
  const [relatedQuestionsList, setRelatedQuestionsList] = useState(null);
  const { profileData } = useSelector((state) => state.profileReducer);
  const addQuestionHandler = async (values) => {
    postChatbotApi(values, profileData.token)
      .then((res) => {
        SuccessAlert({ title: res?.data?.msg });
        getAllChatbot();
        closeModal();
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };
  useEffect(() => {
    getSingleChatbotDropdownApi(profileData.token).then((res) => {
      setRelatedQuestionsList(res.data.result);
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addQuestionSchema}
      onSubmit={(values) => addQuestionHandler(values)}
    >
      {({ values, errors, handleChange, setFieldValue, touched }) => (
        <div className="d-flex ms-5">
          <form className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Question</p>
                </div>
                <input
                  type="text"
                  name="question"
                  className="input-box mt-1"
                  placeholder="Question"
                  onChange={handleChange}
                  value={values.question}
                />
                {errors.question && touched.question && (
                  <p className="text-danger mt-2 mb-3">{errors.question}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Answer</p>
                </div>
                <input
                  type="text"
                  className="input-box mt-1"
                  placeholder="Answer"
                  name="answer"
                  onChange={handleChange}
                  value={values.answer}
                />
                {errors.answer && touched.answer && (
                  <p className="text-danger mt-2 mb-3">{errors.answer}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Related Questions</p>
                </div>
                <Select
                  isMulti
                  name="relatedQuestions"
                  options={relatedQuestionsList?.map((question) => ({
                    value: question.id,
                    label: question.question,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={values.relatedQuestions.map((id) => ({
                    value: id,
                    label: relatedQuestionsList.find(
                      (question) => question.id === id
                    )?.question,
                  }))}
                  onChange={(selectedOptions) => {
                    const selectedIds = selectedOptions.map(
                      (option) => option.value
                    );
                    setFieldValue("relatedQuestions", selectedIds);
                  }}
                />
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Link</p>
                </div>
                <input
                  type="text"
                  className="input-box mt-1"
                  placeholder="Link"
                  name="link"
                  onChange={handleChange}
                  value={values.link}
                />
                {errors.link && touched.link && (
                  <p className="text-danger mt-2 mb-3">{errors.link}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Show this question on Display ?</p>
                </div>
                <div>
                  <label>
                    <Field
                      type="radio"
                      name="at_display"
                      checked={values.at_display === true}
                      onChange={() => setFieldValue("at_display", true)}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="at_display"
                      checked={values.at_display === false}
                      onChange={() => setFieldValue("at_display", false)}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <button
                type="button"
                onClick={() => addQuestionHandler(values)}
                className="btn btn-primary"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => closeModal()}
                className="btn btn-danger ms-3"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default AddChatbot;
