import * as Yup from "yup";

export const addUnitSchema = Yup.object().shape({
  bathrooms: Yup.string()
    .required("Bath Rooms is required")
    .min(1, "Bath Rooms must be 1 characters at minimum")
    .max(10, "Bath Rooms more then 10 character"),
  bedrooms: Yup.string()
    .required("Bad Rooms is required")
    .min(1, "Bad Rooms must be 1 characters at minimum")
    .max(10, "Bad Rooms more then 10 character"),
  guests: Yup.string()
    .required("Guests is required")
    .min(1, "Guests must be 1 characters at minimum")
    .max(10, "Guests more then 10 character"),
  description: Yup.string()
    .required("description is required")
    .min(3, "description must be 3 characters at minimum")
    .max(10000, "description more then 10000 character"),
});
