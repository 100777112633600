/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getGalleryTypeApi,
  postGalleryApi,
} from "../../service/GalleryService";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import axios from "axios";
import INLoader from "../../common/loader/inLoader";

const AddGallery = ({ closeModal, getAllGallary }) => {
  const [getType, setGetType] = useState([]);
  const initialValues = {
    type: 1,
    image: "",
  };
  const { profileData } = useSelector((state) => state.profileReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [imagesBuffer, setImagesBuffer] = useState([]);
  const [images, setImages] = useState([]);
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImagesBuffer(selectedImages);
    const imagePreviews = selectedImages.map((image) =>
      URL.createObjectURL(image)
    );

    setImages(imagePreviews);
  };

  const removeImage = (index, e) => {
    e.preventDefault();
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const uploadImages = async () => {
    try {
      const uploadedUrls = await Promise.all(
        imagesBuffer.map(async (image) => {
          const formData = new FormData();
          formData.append("image", image);

          try {
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/upload`,
              formData
            );

            return res.data.result;
          } catch (error) {
            console.error("Error uploading image:", error);
            throw error; // Rethrow error to handle it later
          }
        })
      );
      return uploadedUrls[0]; // Return the array of uploaded URLs
    } catch (error) {
      console.error("Error uploading images:", error);
      return []; // Return an empty array in case of error
    }
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const uploadedUrls = await uploadImages();
      const res = await postGalleryApi(
        { ...values, image: uploadedUrls },
        profileData.token
      );
      SuccessAlert({ title: res?.data?.msg });
      getAllGallary();
      closeModal();
    } catch (err) {
      ErrorAlert({ title: err?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const getTypeBy = () => {
    getGalleryTypeApi(profileData.token)
      .then((res) => {
        setGetType(res?.data?.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };
  useEffect(() => {
    getTypeBy();
  }, []);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onFinish(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
      }) => (
        <div className="d-flex ms-5">
          <form className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="type"
                      value={values.type}
                      label="Type"
                      onChange={handleChange}
                    >
                      {getType &&
                        getType?.map((ele, idx) => (
                          <MenuItem key={idx} value={ele?.id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>

                {errors.type && touched.type && (
                  <p className="text-danger mt-2 mb-3">{errors.type}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Image</p>
                </div>
                <input
                  type="file"
                  name="image"
                  multiple
                  className="input-box mt-1"
                  placeholder="Upload Images"
                  onChange={handleImageChange}
                />
              </div>
              <div className="preview-container d-flex align-items-center gap-2 mt-3">
                {images.map((image, index) => (
                  <div key={index} className="preview-container">
                    <img
                      src={image}
                      alt={`Preview ${index}`}
                      className="image-preview"
                    />
                    <button
                      className="remove-button"
                      onClick={(e) => removeImage(index, e)}
                    >
                      ❌
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {isLoading ? (
              <INLoader />
            ) : (
              <div className="mt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger ms-3"
                >
                  Cancel
                </button>
              </div>
            )}
          </form>
        </div>
      )}
    </Formik>
  );
};

export default AddGallery;
