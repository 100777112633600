import axios from "axios";

export const getMaintenanceApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/maintenance`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};

export const postMaintenanceApi = async (values, token) => {
  return await axios.post(`${process.env.REACT_APP_BASE_URL}/maintenance`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};


export const putMaintenanceApi = async (id,values,token) => {
    return await axios.put(
      `${process.env.REACT_APP_BASE_URL}/maintenance/${id}`,values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  export const deleteMaintenanceApi = async (id,token) => {
    return await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/maintenance/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  
export const getSingleMaintenanceApi = async (id,token) => {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/maintenance/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning" : 0
        },
      }
    );
  };
  
  export const getMaintenanceFlatIdApi = async (token) => {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/flat/listing`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning" : 0
        },
      }
    );
  };