import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const isAuthenticatedUser = localStorage.getItem('loginUser');

  return isAuthenticatedUser ? (
    <>
    {element}
    </>
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default ProtectedRoute;
