import axios from "axios";

export const getBookingApi = async (token, status) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/booking/adminRequests?status=${
      status || "Requested"
    }`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": 0,
      },
    }
  );
};

export const ApprovedStatusApi = async (value, token, id) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/booking/action/${id}`,
    value,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const PayDepositAPI = async (value, token, id) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/booking/refund/${id}`,
    value,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getOneBookingAPI = async (token, id) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/booking/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": 0,
    },
  });
};
