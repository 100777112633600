/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable/CustomTable";
import Actions from "../../common/ActionsButtons/Actions";
import { useSelector } from "react-redux";
import {
  deleteUnitDetailsApi,
  getUnitDetailsApi,
} from "../../service/unitDetailsService";
import AddUnitDetails from "./AddUnitDetails";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ViewUnitDetails from "./ViewUnitDetails";
import EditDetails from "./EditDetails";
import Loader from "../../common/loader/loader";
import Swal from "sweetalert2";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/navigation";

const UnitDetails = () => {
  const [unitDetailsList, setUnitDetailsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  if (location.pathname == "/") {
    navigate(ROUTES.USERS);
  }

  const getAllUnitDetails = async () => {
    setIsLoading(true);
    try {
      const res = await getUnitDetailsApi(profileData.token);
      setUnitDetailsList(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUnitDetails();
  }, []);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Subtitle",
      selector: (row) => row.subtitle,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Actions",
      selector: (row) => (
        <Actions
          row={row}
          viewopenModal={viewopenModal}
          edittopenModal={editModel}
          deleteRecord={deleteRecord}
        />
      ),
      sortable: true,
      reorder: true,
    },
  ];

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const viewopenModal = () => {
    setViewOpen(true);
  };
  const viewcloseModal = () => {
    setViewOpen(false);
  };
  const editModel = () => {
    setEditOpen(true);
  };
  const closeEditModel = () => {
    setEditOpen(false);
  };

  const deleteRecord = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUnitDetailsApi(id, profileData.token)
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: res?.data?.msg,
              icon: "success",
            });
            getAllUnitDetails();
          })
          .catch((error) => {
            ErrorAlert({ title: error?.response?.data?.error });
          });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="m-4">
            {unitDetailsList && (
              <CustomTable
                columns={columns}
                data={unitDetailsList}
                setSearchText={setSearchText}
                searchText={searchText}
                openModal={openModal}
              />
            )}
          </div>
          <Modal open={open} onClose={closeModal} center>
            <h2 className="ms-5">Add Unit Details</h2>
            <AddUnitDetails
              closeModal={closeModal}
              getAllUnitDetails={getAllUnitDetails}
            />
          </Modal>

          <Modal
            open={viewOpen}
            onClose={viewcloseModal}
            center
            styles={{ width: "100px" }}
          >
            <h2 className="ms-5">View Unit Details</h2>
            <ViewUnitDetails closeModal={viewcloseModal} />
          </Modal>

          <Modal open={editOpen} onClose={closeEditModel} center>
            <h2 className="ms-5">Edit Unit Details</h2>
            <EditDetails
              closeModal={closeEditModel}
              getAllUnitDetails={getAllUnitDetails}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default UnitDetails;
