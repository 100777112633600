/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSingleChatbotApi } from "../../service/ChatbotService";
import { Card } from "react-bootstrap";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";

const VieweChatbot = () => {
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({});
  const getSingleChatbot = () => {
    getSingleChatbotApi(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };
  useEffect(() => {
    getSingleChatbot();
  }, []);
  return (
    <div>
      <Card style={{ width: "100%" }}>
        <Card.Body>
          <div className="d-flex gap-5">
            <Card.Title className="w-50">
              <strong>Question</strong> : <br /> {list?.question}
            </Card.Title>
            <Card.Title className="w-50">
              <strong>Answer</strong> : <br /> {list?.answer}
            </Card.Title>
          </div>
          <Card.Title>
            <strong>Related Question List</strong> :
            {list?.relatedQuestionList?.length > 0 ? (
              <ul>
                {list?.relatedQuestionList?.map((elm, index) => {
                  return (
                    <Card.Title>
                      <li key={index}>
                        <div key={index} className="d-flex gap-5">
                          <div key={index} className="w-50">
                            <strong>{index + 1}) </strong>
                            <strong>Question</strong> <br />
                            {elm?.question}
                          </div>
                          <div key={index} className="w-50">
                            <strong>Answer</strong> <br /> {elm?.answer}
                          </div>
                        </div>
                      </li>
                    </Card.Title>
                  );
                })}
              </ul>
            ) : (
              "No related Questions."
            )}
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
};

export default VieweChatbot;
