/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSingleMaintenanceApi } from "../../service/MaintenanceService";
import { Button, Card } from "react-bootstrap";
import moment from "moment";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import { getSingleLeaseBooking } from "../../service/LeaseBookingService";

const ViewLeaseBooking = ({ closeModal }) => {
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({});

  const getSingleMaintenance = () => {
    getSingleLeaseBooking(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };

  useEffect(() => {
    getSingleMaintenance();
  }, []);

  console.log(list, "list");
  return (
    <div>
      <Card style={{ width: "100%", "text-align": "justify" }}>
        <Card.Body>
          <Card.Title>
            <strong>Start Date</strong> :{" "}
            {moment(list?.start_date).format("DD/MM/YYYY")}
          </Card.Title>
          <Card.Title>
            <strong>End Date</strong> :{" "}
            {moment(list?.end_date).format("DD/MM/YYYY")}
          </Card.Title>
          <Card.Title>
            <strong>full name</strong> : {list?.full_name}
          </Card.Title>
          <Card.Title>
            <strong>email</strong> : {list?.email}
          </Card.Title>
          <Card.Title>
            <strong>number</strong> : {list?.number}
          </Card.Title>
          <Button
            variant="primary"
            onClick={() => closeModal()}
            className="mt-3"
          >
            Cancel
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ViewLeaseBooking;
