import axios from "axios";

export const getBlogsApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/blog`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};

export const postBlogsApi = async (values, token) => {
  return await axios.post(`${process.env.REACT_APP_BASE_URL}/blog`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};


export const putBlogsApi = async (id,values,token) => {
    return await axios.put(
      `${process.env.REACT_APP_BASE_URL}/blog/${id}`,values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  export const deleteBlogsApi = async (id,token) => {
    return await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/blog/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  };

  
export const getSingleBlogsApi = async (id,token) => {
    return await axios.get(
      `${process.env.REACT_APP_BASE_URL}/blog/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning" : 0
        },
      }
    );
  };