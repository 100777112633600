import * as Yup from "yup";

export const addUnitDetailSchema = Yup.object().shape({
  title: Yup.string()
    .required("title is required")
    .min(3, "title must be 3 characters at minimum")
    .max(100, "title more then 100 character"),
  subtitle: Yup.string()
    .required("subtitle is required")
    .min(3, "subtitle must be 3 characters at minimum")
    .max(100, "subtitle more then 100 character"),
  description: Yup.string()
    .required("description is required")
    .min(3, "description must be 3 characters at minimum")
    .max(1000, "description more then 10000 character"),
});
