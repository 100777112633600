/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteMinimumStayApi,
  getMinimumStayApi,
} from "../../service/MinimumStayService";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import Actions from "../../common/ActionsButtons/Actions";
import Swal from "sweetalert2";
import Loader from "../../common/loader/loader";
import CustomTable from "../../common/CustomTable/CustomTable";
import { Modal } from "react-responsive-modal";
import AddMinimumStay from "./AddMinimumStay";
import ViewMinimumStay from "./ViewMinimumStay";
import EditMinimumStay from "./EditMinimumStay";
import moment from "moment";

const MinimumStay = () => {
  const [minimumstayList, setMinimumstayList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAllMinimumStay = async () => {
    setIsLoading(true);
    try {
      const res = await getMinimumStayApi(profileData.token);
      setMinimumstayList(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllMinimumStay();
  }, []);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("DD/MM/YYYY"),
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "End Date",
      selector: (row) => moment(row.end_date).format("DD/MM/YYYY"),
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Days",
      selector: (row) => row.no_of_days,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "1 Bed / 1 Bath",
      selector: (row) => row.category_1,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "2 Bed / 2 Bath",
      selector: (row) => row.category_2,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "3 Bed / 3 Bath",
      selector: (row) => row.category_3,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Deluxe 1 Bed / 2 Bath / Home Office",
      selector: (row) => row.category_4,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Deluxe 2 Bed / 3 Bath / Home Office",
      selector: (row) => row.category_5,
      sortable: true,
      reorder: true,
    },
    {
      id: 10,
      name: "Actions",
      selector: (row) => (
        <Actions
          row={row}
          viewopenModal={viewopenModal}
          edittopenModal={editModel}
          deleteRecord={deleteRecord}
        />
      ),
      sortable: true,
      reorder: true,
    },
  ];

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const viewopenModal = () => {
    setViewOpen(true);
  };
  const viewcloseModal = () => {
    setViewOpen(false);
  };
  const editModel = () => {
    setEditOpen(true);
  };
  const closeEditModel = () => {
    setEditOpen(false);
  };

  const deleteRecord = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteMinimumStayApi(id, profileData.token)
          .then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            getAllMinimumStay();
          })
          .catch((error) => {
            ErrorAlert({ title: error?.response?.data?.error });
          });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="m-4">
            {minimumstayList && (
              <CustomTable
                columns={columns}
                data={minimumstayList}
                setSearchText={setSearchText}
                searchText={searchText}
                openModal={openModal}
              />
            )}
          </div>
          <Modal open={open} onClose={closeModal} center>
            <h2 className="ms-5">Add Minimum Stay </h2>
            <AddMinimumStay
              closeModal={closeModal}
              getAllMinimumStay={getAllMinimumStay}
            />
          </Modal>

          <Modal open={viewOpen} onClose={viewcloseModal} center>
            <h2 className="ms-5 me-5">View Minimum Stay </h2>
            <ViewMinimumStay closeModal={viewcloseModal} />
          </Modal>

          <Modal open={editOpen} onClose={closeEditModel} center>
            <h2 className="ms-5">Edit Minimum Stay</h2>
            <EditMinimumStay
              closeModal={closeEditModel}
              getAllMinimumStay={getAllMinimumStay}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default MinimumStay;
