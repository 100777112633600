import React from "react";

const INLoader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <img src="/images/loader-1.gif" alt="Lodding" />
    </div>
  );
};

export default INLoader;
