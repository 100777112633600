import React, { useState } from "react";
import "./login.css";
import image from "../../assets/login.svg";
import { useFormik } from "formik";
import { NavLink, useNavigate } from "react-router-dom";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { loginApi } from "../../service/authService";
import { loginSchema } from "../../schema/authSchema";
import { ROUTES } from "../../config/navigation";
import { useDispatch } from "react-redux";
import { setProfileData } from "../../redux/actions/profileAction";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setLogin = (values, action) => {
    setIsLoading(true);
    loginApi(values)
      .then((res) => {
        dispatch(setProfileData(res?.data?.result));
        localStorage.setItem("loginUser", JSON.stringify(res.data.result));
        navigate("/unit-details");
        action.resetForm();
        SuccessAlert({ title: res?.data?.msg });
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        setIsLoading(false);
      });
  };

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      validationSchema: loginSchema,
      initialValues: initialValues,
      onSubmit: (values, action) => {
        setLogin(values, action);
      },
    });

  return (
    <div className="form-login-body">
      <div className="container-fluid">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="login-body container-fluid">
            <div className="container">
              <div className="row">
                <div className="col-md-5 input_fields">
                  <form onSubmit={handleSubmit}>
                    <div className="login-text">
                      <h4>Login</h4>
                      <div className="form-field ">
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          className="form-control "
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                      </div>
                      <div className="form-field position-relative">
                        <input
                          name="password"
                          placeholder="Enter Password"
                          className="form-control"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type={isShow ? "text" : "password"}
                        />
                        <span
                          onClick={() => setIsShow(!isShow)}
                          className="eye-block"
                        >
                          {isShow ? <BsEyeSlashFill /> : <BsEyeFill />}
                        </span>
                        {errors.password && touched.password ? (
                          <p className="form-error">{errors.password}</p>
                        ) : null}
                      </div>
                      <NavLink
                        className="cp link-connect"
                        to={ROUTES.FORGET_PASSWORD}
                      >
                        Forget Password?
                      </NavLink>

                      <button className="btn custom_btn">Sign In</button>
                    </div>
                  </form>
                </div>
                <div className="col-md-7">
                  <div className="login-img">
                    <img src={image} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
