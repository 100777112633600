export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/reset-password",
  PAGE_NOT_FOUND: "/page-not-found",
  UNIT_DETAILS: "/unit-details",
  UNIT_DETAILS_ADD: "/unit-details/add",
  ANUMITES: "/amenities",
  GALLERYTYPE: "/gallery-type",
  GALLERY: "/gallery",
  ABOUTUS: "/about-us",
  TESTIMONIALS: "/testimonials",
  BLOGS: "/blogs",
  APPARTMENT: "/appartment",
  MAINTENANCE: "/maintenance",
  CONTECTUS: "/contect-us",
  CHATBOT: "/chatbot",
  BOOKINGS: "/bookings",
  UNITS: "/units",
  MINUMUMSTAY: "/minimum-stay",
  ONEBOOKING: "/one-booking",
  PROFILE: "/profile",
  LEASE_BOOKING: "/lease-booking",
  USERS: "/users",
  LEASE_UNIT: "/lease-unit",
};
