import { Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { postUnitDetailsApi } from "../../service/unitDetailsService";
import axios from "axios";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";
import { addUnitDetailSchema } from "../../schema/unitDetailsSchema";
import Image from "../../common/image drag/imageList";

const AddUnitDetails = (props) => {
  const { closeModal, getAllUnitDetails } = props;
  const initialValues = {
    title: "",
    subtitle: "",
    thumbnail: "",
    description: "",
    features: "",
    images: "",
  };

  const { profileData } = useSelector((state) => state.profileReducer);
  const [images, setImages] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [imagesBuffer, setImagesBuffer] = useState([]);
  const [thumbnailBuffer, setThumbnailBuffer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (
    e,
    setBuffer,
    setImageState,
    isThumbnail = false
  ) => {
    const selectedImages = Array.from(e.target.files);
    setBuffer(selectedImages);

    const imagePreviews = selectedImages.map((image) =>
      URL.createObjectURL(image)
    );

    if (selectedImages.length === 1) {
      setImageState((prevState) => [...prevState, ...imagePreviews]);
    } else {
      setImageState(imagePreviews);
    }

    // Update thumbnail only if isThumbnail is true
    if (isThumbnail && selectedImages.length === 1) {
      setThumbnail(imagePreviews); // Replace the entire array with the new thumbnail
    }
  };

  const removeItem = (index, array, setArray, buffer, setBuffer, e) => {
    e.preventDefault();
    const updatedArray = [...array];
    updatedArray.splice(index, 1);
    setArray(updatedArray);

    const updatedBuffer = [...buffer];
    updatedBuffer.splice(index, 1);
    setBuffer(updatedBuffer);
  };

  const uploadImages = async (imageBuffer) => {
    try {
      setIsLoading(true);
      const uploadedUrls = await Promise.all(
        imageBuffer.map(async (image) => {
          const formData = new FormData();
          formData.append("image", image);

          try {
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/upload`,
              formData
            );

            return res.data.result;
          } catch (error) {
            console.error("Error uploading image:", error);
            throw error;
          }
        })
      );
      setIsLoading(false);
      return uploadedUrls;
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading images:", error);
      return [];
    }
  };

  const uploadThumbnail = async (thumbnailBuffer) => {
    try {
      setIsLoading(true);
      const uploadedUrls = await Promise.all(
        thumbnailBuffer.map(async (image) => {
          const formData = new FormData();
          formData.append("image", image);

          try {
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/upload`,
              formData
            );

            return res.data.result;
          } catch (error) {
            console.error("Error uploading thumbnail:", error);
            throw error;
          }
        })
      );
      setIsLoading(false);
      return uploadedUrls;
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading thumbnails:", error);
      return [];
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const uploadedUrls = await uploadImages(imagesBuffer);
      const uploadedThumbnails = await uploadThumbnail(thumbnailBuffer);
      const res = await postUnitDetailsApi(
        { ...values, images: uploadedUrls, thumbnail: uploadedThumbnails },
        profileData.token
      );
      getAllUnitDetails();
      closeModal();
      SuccessAlert({ title: res.data.msg });
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData("text/plain");
    const newImages = [...images];
    const dragImage = newImages[dragIndex];
    newImages.splice(dragIndex, 1);
    newImages.splice(index, 0, dragImage);
    setImages(newImages);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={addUnitDetailSchema}
          onSubmit={(values) => {
            onFinish(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <div className="d-flex ms-5">
              <form className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Title</p>
                    </div>
                    <input
                      type="text"
                      name="title"
                      className="input-box mt-1"
                      placeholder="Title"
                      onChange={handleChange}
                      value={values.title}
                    />
                    {errors.title && touched.title && (
                      <p className="text-danger mt-2 mb-3">{errors.title}</p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Sub Title</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="Sub Title"
                      name="subtitle"
                      onChange={handleChange}
                      value={values.subtitle}
                    />
                    {errors.subtitle && touched.subtitle && (
                      <p className="text-danger mt-2 mb-3">{errors.subtitle}</p>
                    )}
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Description</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="Description"
                      name="description"
                      onChange={handleChange}
                      value={values.description}
                    />
                    {errors.description && touched.description && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.description}
                      </p>
                    )}
                  </div>
                </div>

                {/* <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Dimentions</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="write in 3 x 3 manner"
                      name="dimentions"
                      onChange={handleChange}
                      value={values.dimentions}
                    />
                    {errors.dimentions && touched.dimentions && (
                      <p className="text-danger mt-2 mb-3">
                        {errors.dimentions}
                      </p>
                    )}
                  </div>
                </div> */}

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Images</p>
                    </div>
                    <input
                      type="file"
                      name="images"
                      multiple
                      className="input-box mt-1"
                      placeholder="Upload Images"
                      onChange={(e) =>
                        handleFileChange(e, setImagesBuffer, setImages)
                      }
                    />
                  </div>

                  <div className="preview-container d-flex align-items-center gap-2 mt-3">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {images.map((src, index) => (
                        <div key={index} className="preview-container">
                          <Image
                            key={index}
                            src={src}
                            className="image-preview"
                            index={index}
                            handleDragStart={handleDragStart}
                            handleDragOver={handleDragOver}
                            handleDrop={handleDrop}
                          />
                          <button
                            className="remove-button"
                            onClick={(e) =>
                              removeItem(
                                index,
                                images,
                                setImages,
                                imagesBuffer,
                                setImagesBuffer,
                                e
                              )
                            }
                          >
                            ❌
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Thumbnail</p>
                    </div>
                    <input
                      type="file"
                      name="images"
                      className="input-box mt-1"
                      placeholder="Upload Images"
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setThumbnailBuffer,
                          setThumbnail,
                          true
                        )
                      }
                    />
                  </div>
                  <div className="preview-container d-flex align-items-center gap-2 mt-3">
                    {thumbnail.map((image, index) => (
                      <div key={index} className="preview-container">
                        <img
                          src={image}
                          alt={`Preview ${index}`}
                          className="image-preview"
                        />
                        <button
                          className="remove-button"
                          onClick={(e) =>
                            removeItem(
                              index,
                              thumbnail,
                              setThumbnail,
                              thumbnailBuffer,
                              setThumbnailBuffer,
                              e
                            )
                          }
                        >
                          ❌
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-danger ms-3"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddUnitDetails;
