import axios from "axios";

export const loginApi = async (data) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/admin/login`,
    data
  );
};

export const forgetPasswordApi = async (data) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/admin/forget-password`,
    data
  );
};

export const resetPasswordApi = async (data, token) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/admin/reset-password`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const validateResetTokenApi = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/admin/token-check`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning" : 0
      },
    }
  );
};

export const getAllUsers = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/admin/users`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning" : 0
      },
    }
  );
};
