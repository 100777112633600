/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApprovedStatusApi, getBookingApi } from "../../service/BookingService";
import CustomTable from "../../common/CustomTable/CustomTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button } from "react-bootstrap";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";
import Swal from "sweetalert2";
import moment from "moment";
import Modal from "react-responsive-modal";
import { Formik } from "formik";

const RequestedBooking = (props) => {
  const [bookingList, setBookingList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [commentData, setCommentData] = useState({
    id: null,
    comment: "",
    username: "",
    email: "",
    action: "comment",
  });

  const getAllBooking = async () => {
    setIsLoading(true);
    try {
      const res = await getBookingApi(profileData.token, "Requested");
      setBookingList(res?.data?.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const OpenCommentModel = (row) => {
    setCommentData({
      id: row.id,
      username: row.full_name,
      email: row.email,
      action: "comment",
    });
    setOpen(true);
  };

  const closeModel = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllBooking();
  }, []);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Name",
      selector: (row) => row.full_name,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Number",
      selector: (row) => row.number,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("DD/MM/YYYY"),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "End Date",
      selector: (row) => moment(row.end_date).format("DD/MM/YYYY"),
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 6,
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 7,
      name: "Deposit",
      selector: (row) => row.deposit,
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 8,
      name: "Reason",
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "view",
      selector: (row) => (
        <div>
          <p
            className="m-1 cp viewModel"
            onClick={() => {
              props.setIsOneBooking({ id: row.id, view: true });
              props.setIsApiCall(true);
            }}
          >
            <VisibilityIcon />
          </p>
        </div>
      ),
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 10,
      name: "",
      selector: (row) => (
        <Button variant="primary" onClick={() => OpenCommentModel(row)}>
          <span style={{ padding: "6px !important" }}>Add Comment</span>
        </Button>
      ),
    },
    {
      id: 11,
      name: "",
      selector: (row) => (
        <Button variant="primary" onClick={() => ApprovedStatus(row)}>
          Approved
        </Button>
      ),
    },
    {
      id: 12,
      name: "",
      selector: (row) => (
        <Button variant="danger" onClick={() => RejectedStatus(row)}>
          Rejected
        </Button>
      ),
    },
  ];

  const ApprovedStatus = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve Request!",
    }).then((result) => {
      if (result.isConfirmed) {
        const value = {
          xRefNum: row.userXRefNum,
          action: "approve",
        };
        ApprovedStatusApi(value, profileData.token, row.id)
          .then((res) => {
            SuccessAlert({ title: res?.data?.msg });
            getAllBooking();
          })
          .catch((err) => {
            ErrorAlert({ title: err?.response?.data?.error });
          });
      }
    });
  };

  const RejectedStatus = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject Request!",
    }).then((result) => {
      if (result.isConfirmed) {
        const value = {
          xRefNum: row.userXRefNum,
          action: "reject",
        };
        ApprovedStatusApi(value, profileData.token, row.id)
          .then((res) => {
            SuccessAlert({ title: res?.data?.msg });
            getAllBooking();
          })
          .catch((err) => {
            ErrorAlert({ title: err?.response?.data?.error });
          });
      }
    });
  };

  const onFinish = (values) => {
    setIsLoading(true);
    closeModel();
    ApprovedStatusApi(values, profileData.token, values.id)
      .then((res) => {
        SuccessAlert({ title: res?.data?.msg });
        getAllBooking();
        setIsLoading(false);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="m-4">
            {bookingList && (
              <CustomTable
                columns={columns}
                data={bookingList}
                setSearchText={setSearchText}
                searchText={searchText}
                hideModel="add-model"
              />
            )}
          </div>
          <Modal open={open} onClose={closeModel} center>
            <h2 className="ms-5">Add Comment</h2>
            <Formik
              initialValues={commentData}
              onSubmit={(values) => {
                onFinish(values);
              }}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <div className="d-flex ms-5">
                  <form className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>Comment</p>
                        </div>
                        <input
                          type="text"
                          className="input-box mt-1"
                          placeholder="Add Comment"
                          name="comment"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.comment}
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        onClick={closeModel}
                        className="btn btn-danger ms-3"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
          </Modal>
        </>
      )}
    </>
  );
};

export default RequestedBooking;
