/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSingleGalleryTypeApi } from "../../service/GalleryTypeService";
import { Button, Card } from "react-bootstrap";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";

const ViewGalleryType = ({ closeModal }) => {
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({});
  const getSingleUnit = () => {
    getSingleGalleryTypeApi(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        console.log("err", err);
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };
  useEffect(() => {
    getSingleUnit();
  }, []);
  return (
    <Card style={{ width: "100%" }}>
      <Card.Body>
        <Card.Title>
          <strong>Name</strong> : {list?.name}
        </Card.Title>
        <Button variant="primary" onClick={() => closeModal()} className="mt-3">
          Cancel
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ViewGalleryType;
