/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { NavLink } from "react-bootstrap";
import RequestedBooking from "./RequestedBookings";
import ApprovedBooking from "./ApprovedBookings";
import RejectedBookings from "./RejectedBookings";
import PastBookings from "./PastBookings";
import OneBooking from "./OneBooking";

const Booking = () => {
  const [getType, setGetType] = useState("Requested");
  const [isOneBooking, setIsOneBooking] = useState({ id: 0, view: false });
  const [isApiCall, setIsApiCall] = useState(false);

  return (
    <div className="content-wrapper">
      {!isOneBooking.view ? (
        <ul
          className="nav nav-pills"
          style={{ marginLeft: "1.5rem", marginTop: "-2rem" }}
        >
          <li className="nav-item mt-5 me-2 ms-2">
            <NavLink
              className={`nav-link ${
                getType === "Requested" ? "activeTab" : ""
              }`}
              onClick={() => setGetType("Requested")}
            >
              Requested
            </NavLink>
          </li>
          <li className="nav-item mt-5 me-2 ms-2">
            <NavLink
              className={`nav-link ${
                getType === "Approved" ? "activeTab" : ""
              }`}
              onClick={() => setGetType("Approved")}
            >
              Approved
            </NavLink>
          </li>
          <li className="nav-item mt-5 me-2 ms-2">
            <NavLink
              className={`nav-link ${
                getType === "Rejected" ? "activeTab" : ""
              }`}
              onClick={() => setGetType("Rejected")}
            >
              Rejected
            </NavLink>
          </li>
          <li className="nav-item mt-5 me-2 ms-2">
            <NavLink
              className={`nav-link ${getType === "Past" ? "activeTab" : ""}`}
              onClick={() => setGetType("Past")}
            >
              Past
            </NavLink>
          </li>
        </ul>
      ) : null}

      {getType === "Requested" && !isOneBooking.view && (
        <RequestedBooking
          setIsOneBooking={setIsOneBooking}
          setIsApiCall={setIsApiCall}
        />
      )}
      {getType === "Approved" && !isOneBooking.view && (
        <ApprovedBooking
          setIsOneBooking={setIsOneBooking}
          setIsApiCall={setIsApiCall}
        />
      )}
      {getType === "Rejected" && !isOneBooking.view && (
        <RejectedBookings
          setIsOneBooking={setIsOneBooking}
          setIsApiCall={setIsApiCall}
        />
      )}
      {getType === "Past" && !isOneBooking.view && (
        <PastBookings
          setIsOneBooking={setIsOneBooking}
          setIsApiCall={setIsApiCall}
        />
      )}
      {isOneBooking.view && (
        <OneBooking
          id={isOneBooking.id}
          isApiCall={isApiCall}
          setIsOneBooking={setIsOneBooking}
          setIsApiCall={setIsApiCall}
        />
      )}
    </div>
  );
};

export default Booking;
