import React from "react";
import { useSelector } from "react-redux";
import { postGalleryTypeApi } from "../../service/GalleryTypeService";
import { Formik } from "formik";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import { addGalleryTypeSchema } from "../../schema/gallerySchema";

const AddGalleryType = ({ closeModal, getAllUnitDetails }) => {
  const initialValues = { name: "" };
  const { profileData } = useSelector((state) => state.profileReducer);
  const onFinish = async (values) => {
    postGalleryTypeApi(values, profileData.token)
      .then((res) => {
        SuccessAlert({ title: res?.data?.msg });
        getAllUnitDetails();
        closeModal();
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addGalleryTypeSchema}
      onSubmit={(values) => {
        onFinish(values);
      }}
    >
      {({ values, errors, handleChange, handleSubmit, touched }) => (
        <div className="d-flex ms-5">
          <form className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Name</p>
                </div>
                <input
                  type="text"
                  name="name"
                  className="input-box mt-1"
                  placeholder="Name"
                  onChange={handleChange}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <p className="text-danger mt-2 mb-3">{errors.name}</p>
                )}
              </div>
            </div>

            <div className="mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-danger ms-3"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default AddGalleryType;
