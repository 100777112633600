// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { deleteBlogsApi, getBlogsApi } from "../../service/BlogsService";
// import Actions from "../../common/ActionsButtons/Actions";
// import CustomTable from "../../common/CustomTable/CustomTable";
// import Modal from "react-responsive-modal";
// import { ErrorAlert } from "../../common/sweetalert/sweetalert";
// import Loader from "../../common/loader/loader";
// import Swal from "sweetalert2";
// import moment from "moment";
// import { getTestimonialsApi } from "../../service/TestimonialsService";
// import AddTestimonials from "./AddTestimoni sdals";
// import EditTestimonials from "./EditTestimosnials";

// const Blogs = () => {
//   const [blogList, setBlogList] = useState([]);
//   const [searchText, setSearchText] = useState("");
//   const { profileData } = useSelector((state) => state.profileReducer);
//   const [open, setOpen] = useState(false);
//   const [viewOpen, setViewOpen] = useState(false);
//   const [editOpen, setEditOpen] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);

//   const getAllBlog = async () => {
//     setIsLoading(true);
//     try {
//       const res = await getTestimonialsApi(profileData.token);
//       setBlogList(res?.data?.result);
//     } catch (error) {
//       ErrorAlert({ title: error?.response?.data?.error });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     getAllBlog();
//   }, []);

//   const columns = [
//     {
//       id: 1,
//       name: "Id",
//       selector: (row, idx) => idx + 1,
//       sortable: true,
//       reorder: true,
//     },
//     {
//       id: 2,
//       name: "Title",
//       selector: (row) => row.title,
//       sortable: true,
//       reorder: true,
//     },
//     {
//       id: 3,
//       name: "Date",
//       selector: (row) => moment(row.date).format("DD-MM-YYYY"),
//       sortable: true,
//       reorder: true,
//     },
//     {
//       id: 4,
//       name: "Author",
//       selector: (row) => row.author,
//       sortable: true,
//       reorder: true,
//     },
//     {
//       id: 5,
//       name: "Description",
//       selector: (row) => row.description,
//       sortable: true,
//       reorder: true,
//     },
//     {
//       id: 8,
//       name: "Actions",
//       selector: (row) => (
//         <Actions
//           row={row}
//           viewopenModal={viewopenModal}
//           edittopenModal={editModel}
//           deleteRecord={deleteRecord}
//         />
//       ),
//       sortable: true,
//       right: "true",
//       reorder: true,
//     },
//   ];

//   const openModal = () => {
//     setOpen(true);
//   };
//   const closeModal = () => {
//     setOpen(false);
//   };
//   const viewopenModal = () => {
//     setViewOpen(true);
//   };
//   const viewcloseModal = () => {
//     setViewOpen(false);
//   };
//   const editModel = () => {
//     setEditOpen(true);
//   };
//   const closeEditModel = () => {
//     setEditOpen(false);
//   };

//   const deleteRecord = (id) => {
//     Swal.fire({
//       title: "Are you sure?",
//       text: "You won't be able to revert this!",
//       icon: "warning",
//       showCancelButton: true,
//       confirmButtonColor: "#3085d6",
//       cancelButtonColor: "#d33",
//       confirmButtonText: "Yes, delete it!",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         deleteBlogsApi(id, profileData.token)
//           .then((res) => {
//             Swal.fire({
//               title: "Deleted!",
//               text: res?.data?.msg,
//               icon: "success",
//             });
//             getAllBlog();
//           })
//           .catch((error) => {
//             ErrorAlert({ title: error?.response?.data?.error });
//           });
//       }
//     });
//   };

//   return (
//     <>
//       {isLoading ? (
//         <Loader />
//       ) : (
//         <div className="content-wrapper">
//           <div className="m-4">
//             {blogList && (
//               <CustomTable
//                 columns={columns}
//                 data={blogList}
//                 setSearchText={setSearchText}
//                 searchText={searchText}
//                 openModal={openModal}
//               />
//             )}
//           </div>
//           <Modal open={open} onClose={closeModal} center>
//             <h2 className="ms-5">Add Blog</h2>
//             <AddTesstimonials closeModal={closeModal} getAllBlog={getAllBlog} />
//           </Modal>

//           <Modal open={viewOpen} onClose={viewcloseModal} center>
//             <h2 className="ms-5 me-5">View Blog</h2>
//             <ViewTestimonials closeModal={viewcloseModal} />
//           </Modal>

//           <Modal open={editOpen} onClose={closeEditModel} center>
//             <h2 className="ms-5">Edit Blog </h2>
//             <EditsTestimonials
//               closeModal={closeEditModel}
//               getAllBlog={getAllBlog}
//             />
//           </Modal>
//         </div>
//       )}
//     </>
//   );
// };

// export default Blogs;

import React from "react";

const Testimonials = () => {
  return <div></div>;
};

export default Testimonials;
