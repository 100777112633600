import React, { useEffect, useState } from "react";
import resetPasswordSvg from "../../assets/resetPassword.svg";
import linkExpiredSvg from "../../assets/link_expired.svg";
import linkUsedSvg from "../../assets/link_used.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import "./resetPassword.css";
import "../Login/login.css";
import {
  resetPasswordApi,
  validateResetTokenApi,
} from "../../service/authService";
import { ROUTES } from "../../config/navigation";
import { resetPasswordSchema } from "../../schema/authSchema";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const [linkIsExpired, setLinkIsExpired] = useState(false);
  const [linkUsed, setLinkUsed] = useState(false);
  const navigate = useNavigate();
  const token = new URLSearchParams(window.location.search).get("token");
  const [isShowNew, setIsShowNew] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateToken = () => {
    setIsLoading(true);
    validateResetTokenApi(token)
      .then((res) => {
        console.log("res", res);
        if (res.data.result.isExpired) {
          setLinkIsExpired(!linkIsExpired);
        } else if (res.data.result.isUsed) {
          setLinkUsed(!linkUsed);
        }
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPassword = (values, action) => {
    setIsLoading(true);
    console.log(values);
    resetPasswordApi(values, token)
      .then((res) => {
        navigate(ROUTES.LOGIN);
        SuccessAlert({ title: res?.data?.msg });
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        setIsLoading(false);
      });
  };

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      validationSchema: resetPasswordSchema,
      initialValues: initialValues,
      onSubmit: (values, action) => {
        setPassword(values, action);
      },
    });

  return (
    <div className="form-login-body">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          {!linkIsExpired && !linkUsed && (
            <div className="login-body container-fluid">
              <div className="container">
                <div className="row">
                  <div className="col-md-5 center_div">
                    <form onSubmit={handleSubmit}>
                      <div className="login-text ">
                        <h4>Set New Password</h4>
                        <div className="form-field position-relative">
                          <input
                            placeholder="New Password"
                            className="form-control "
                            name="password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={isShowNew ? "text" : "password"}
                          />
                          <span
                            onClick={() => setIsShowNew(!isShowNew)}
                            className="eye-block"
                          >
                            {isShowNew ? <BsEyeSlashFill /> : <BsEyeFill />}
                          </span>
                          {errors.password && touched.password ? (
                            <p className="form-error">{errors.password}</p>
                          ) : null}
                        </div>
                        <div className="form-field position-relative">
                          <input
                            placeholder="Confirm Password"
                            className=" form-control"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={isShowConfirm ? "text" : "password"}
                          />
                          <span
                            onClick={() => setIsShowConfirm(!isShowConfirm)}
                            className="eye-block"
                          >
                            {isShowConfirm ? <BsEyeSlashFill /> : <BsEyeFill />}
                          </span>

                          {errors.confirmPassword && touched.confirmPassword ? (
                            <p className="form-error">
                              {errors.confirmPassword}
                            </p>
                          ) : null}
                        </div>

                        <button className="btn custom_btn">Submit</button>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-7">
                    <div className="login-img">
                      <img src={resetPasswordSvg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {linkIsExpired && (
            <div className="expired">
              <div className="login-body container-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col-md-5 center_div">
                      <p>
                        Link Expired, Kindly generate new link from
                        <NavLink to={ROUTES.FORGET_PASSWORD}>here</NavLink>
                      </p>
                    </div>
                    <div className="col-md-7">
                      <div className="login-img">
                        <img src={linkExpiredSvg} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {linkUsed && (
            <div className="used">
              <div className="login-body container-fluid">
                <div className="container">
                  <div className="row">
                    <div className="col-md-5 center_div">
                      <p>
                        Link is Used, Kindly login from below link from
                        <NavLink to={ROUTES.LOGIN}>here</NavLink>
                      </p>
                    </div>
                    <div className="col-md-7">
                      <div className="login-img">
                        <img src={linkUsedSvg} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
