/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  deleteGalleryApi,
  getGalleryApi,
  getGalleryTypeApi,
} from "../../service/GalleryService";
import Actions from "../../common/ActionsButtons/Actions";
import CustomTable from "../../common/CustomTable/CustomTable";
import AddGallery from "./AddGallery";
import ViewGallery from "./ViewGallery";
import Modal from "react-responsive-modal";
import { NavLink } from "react-router-dom";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import Swal from "sweetalert2";
import Loader from "../../common/loader/loader";

const Gallery = () => {
  const [galleryList, setGalleryList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [getType, setGetType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentType, setCurrentType] = useState(null);

  const getTypeBy = () => {
    getGalleryTypeApi(profileData.token)
      .then((res) => {
        setGetType(res?.data?.result?.reverse());
        setCurrentType(res?.data?.result[0]?.id);
      })
      .catch((error) => ErrorAlert({ title: error?.response?.data?.error }));
  };

  useEffect(() => {
    getTypeBy();
  }, []);

  const getAllGallary = async () => {
    try {
      setIsLoading(true);
      const res = await getGalleryApi(profileData.token, currentType);
      setGalleryList(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllGallary();
  }, [currentType]);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Gallery Type",
      selector: (row) => row.galleryType.name,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Image",
      selector: (row) => (
        <img src={row?.image} alt="" width={100} className="image-block" />
      ),
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 4,
      name: "Actions",
      selector: (row) => (
        <Actions
          row={row}
          viewopenModal={viewopenModal}
          deleteRecord={deleteRecord}
          hiddenEdit="EditModel"
        />
      ),
      sortable: true,
      reorder: true,
    },
  ];

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const viewopenModal = () => {
    setViewOpen(true);
  };
  const viewcloseModal = () => {
    setViewOpen(false);
  };

  const deleteRecord = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteGalleryApi(id, profileData.token)
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: res?.data?.msg,
              icon: "success",
            });
            getAllGallary();
          })
          .catch((error) => {
            ErrorAlert({ title: error?.response?.data?.error });
          });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <ul
            className="nav nav-pills"
            style={{ marginLeft: "1.5rem", marginTop: "-2rem" }}
          >
            {getType &&
              getType.map((item) => (
                <li key={item.id} className="nav-item mt-5 me-2 ms-2">
                  <NavLink
                    className={`nav-link ${
                      currentType === item.id ? "activeTab" : ""
                    }`}
                    onClick={() => setCurrentType(item?.id)}
                  >
                    {item?.name}
                  </NavLink>
                </li>
              ))}
          </ul>
          <div className="m-4">
            {galleryList && (
              <CustomTable
                columns={columns}
                data={galleryList}
                setSearchText={setSearchText}
                searchText={searchText}
                openModal={openModal}
              />
            )}
          </div>
          <Modal open={open} onClose={closeModal} center>
            <h2 className="ms-5">Add Gallary</h2>
            <AddGallery closeModal={closeModal} getAllGallary={getAllGallary} />
          </Modal>

          <Modal open={viewOpen} onClose={viewcloseModal} center>
            <h2 className="ms-5 me-5">Vide Gallary</h2>
            <ViewGallery closeModal={viewcloseModal} />
          </Modal>
        </div>
      )}
    </>
  );
};

export default Gallery;
