/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import { addmaintentanceSchema } from "../../schema/maintenance";
import {
  getLeaseBooking,
  getSingleLeaseBooking,
  postLeaseBooking,
  putLeaseBooking,
} from "../../service/LeaseBookingService";
import { addLeaseSchema } from "../../schema/leaseSchema";
import { getMaintenanceFlatIdApi } from "../../service/MaintenanceService";

const EditLeaseBooking = ({ closeModal, getAllgetMaintenanceApiDetails }) => {
  const [flatId, setFlatId] = useState([]);

  const [details, setDetails] = useState({
    start_date: new Date(),
    end_date: new Date(),
    full_name: "",
    email: "",
    number: "",
    flatId: 1,
  });

  const { profileData } = useSelector((state) => state.profileReducer);
  const { id } = useSelector((state) => state.unitDetailsReducer);

  const onFinish = async (values) => {
    putLeaseBooking(id, values, profileData.token)
      .then((res) => {
        SuccessAlert({ title: res?.data?.msg });
        getAllgetMaintenanceApiDetails();
        closeModal();
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };

  const getSingleMaintenance = () => {
    getSingleLeaseBooking(id, profileData.token)
      .then((res) => {
        setDetails(res.data.result[0]);
      })
      .catch((err) => {
        ErrorAlert({ title: err });
      });
  };

  useEffect(() => {
    getSingleMaintenance();
  }, [id]);

  const getTypeBy = () => {
    getMaintenanceFlatIdApi(profileData.token)
      .then((res) => {
        setFlatId(res?.data?.result);
      })
      .catch((err) => ErrorAlert({ title: err }));
  };

  useEffect(() => {
    getTypeBy();
  }, []);

  const filteredFlat = flatId.find((flat) => flat.id === details?.flatId);

  console.log(flatId,'flatId');
  console.log(filteredFlat,'filteredFlat');
  return (
    <Formik
      enableReinitialize
      initialValues={details}
      validationSchema={addLeaseSchema}
      onSubmit={(values) => {
        onFinish(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="d-flex ms-5">
          <form className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
              <div className="form-group">
                {console.log(values.flatId, "values.flatId")}
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Flat</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="flatId"
                      value={values.flatId}
                      label="Flat"
                      onChange={handleChange}
                    >
                      {flatId &&
                        flatId.map((flat, idx) => (
                          <MenuItem key={idx} value={flat.id}>
                            {flat.unit_no}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {errors.flatId && touched.flatId && (
                    <p className="text-danger mt-2 mb-3">{errors.flatId}</p>
                  )}
                </Box>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>start date</p>
                </div>
                <DatePicker
                  name="start_date"
                  className="input-box mt-1"
                  placeholder="start date"
                  onChange={(date) => setFieldValue("start_date", date)}
                  onBlur={handleBlur}
                  selected={values?.start_date}
                />
              </div>
              {errors.start_date && touched.start_date && (
                <p className="text-danger mt-2 mb-3">{errors.start_date}</p>
              )}
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>end date</p>
                </div>
                <DatePicker
                  name="end_date"
                  className="input-box mt-1"
                  placeholder="end date"
                  onChange={(date) => setFieldValue("end_date", date)}
                  onBlur={handleBlur}
                  selected={values?.end_date}
                />
              </div>
              {errors.end_date && touched.end_date && (
                <p className="text-danger mt-2 mb-3">{errors.end_date}</p>
              )}
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>full name</p>
                </div>
                <input
                  type="text"
                  name="full_name"
                  className="input-box mt-1"
                  placeholder="full name"
                  onChange={handleChange}
                  value={values.full_name}
                />
                {errors.full_name && touched.full_name && (
                  <p className="text-danger mt-2 mb-3">{errors.full_name}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>email</p>
                </div>
                <input
                  type="text"
                  name="email"
                  className="input-box mt-1"
                  placeholder="full name"
                  onChange={handleChange}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <p className="text-danger mt-2 mb-3">{errors.email}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>number</p>
                </div>
                <input
                  type="number"
                  name="number"
                  className="input-box mt-1"
                  placeholder="full name"
                  onChange={handleChange}
                  value={values.number}
                />
                {errors.number && touched.number && (
                  <p className="text-danger mt-2 mb-3">{errors.number}</p>
                )}
              </div>
            </div>

            <div className="mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-danger ms-3"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default EditLeaseBooking;
