import axios from "axios";

export const getMinimumStayApi = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/minimum-stay`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning" : 0
      },
    }
  );
};

export const postMinimumStayApi = async (values,token) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/minimum-stay`,values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const putMinimumStayApi = async (id,values,token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/minimum-stay/${id}`,values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const deleteMinimumStayApi = async (id,token) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/minimum-stay/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSingleMinimumStayApi = async (id,token) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/minimum-stay/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning" : 0
      },
    }
  );
};