import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { setId, setisEdit } from "../../redux/actions/unitDetailsAction";
import "./actions.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Actions = ({
  row,
  viewopenModal,
  edittopenModal,
  deleteRecord,
  hiddenEdit,
  hiddenDelete,
  arrange,
  moveUpward,
  moveDown,
}) => {
  const dispatch = useDispatch();

  const onViewClick = (id) => {
    dispatch(setId(id));
    viewopenModal();
  };
  const onEditClick = (id) => {
    dispatch(setId(id));
    dispatch(setisEdit(true));
    edittopenModal();
  };

  return (
    <div className="d-flex justify-content-evenly" style={{ color: "#004256" }}>
      <p className="m-1 cp viewModel" onClick={() => onViewClick(row.id)}>
        <VisibilityIcon />
      </p>
      <p
        className={`m-1 cp ${hiddenEdit || ""}`}
        onClick={() => onEditClick(row.id)}
      >
        <ModeEditIcon />
      </p>
      <p className={`m-1 cp ${hiddenDelete || ""}`}>
        <DeleteIcon onClick={() => deleteRecord(row.id)} />
      </p>
      {arrange && (
        <>
          <p className={`m-1 cp ${hiddenDelete || ""}`}>
            <ArrowUpwardIcon onClick={() => moveUpward(row.id)} />
          </p>
          <p className={`m-1 cp ${hiddenDelete || ""}`}>
            <ArrowDownwardIcon onClick={() => moveDown(row.id)} />
          </p>
        </>
      )}
    </div>
  );
};

export default Actions;
