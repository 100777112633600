import axios from "axios";

export const getUnitDetailsApi = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/unit-details`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning" : 0
      },
    }
  );
};

export const postUnitDetailsApi = async (values,token) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/unit-details`,values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const putUnitDetailsApi = async (id,values,token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/unit-details/${id}`,values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const deleteUnitDetailsApi = async (id,token) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/unit-details/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSingleUnitDetailsApi = async (id,token) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/unit-details/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning" : 0
      },
    }
  );
};