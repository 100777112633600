import axios from "axios";

export const getGalleryApi = async (token , id) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/gallery?type=${id ? id : `All`}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};

export const postGalleryApi = async (values, token) => {
  return await axios.post(`${process.env.REACT_APP_BASE_URL}/gallery`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const putGalleryApi = async (id, values, token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/gallery/${id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteGalleryApi = async (id, token) => {
  return await axios.delete(`${process.env.REACT_APP_BASE_URL}/gallery/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSingleGalleryApi = async (id, token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/gallery/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};

export const getGalleryTypeApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/gallery-type`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};