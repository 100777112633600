import axios from "axios";

export const getUnitsApi = async (token, floorType) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/flat?floor=${floorType}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning" : 0
      },
    }
  );
};

export const postUnitsApi = async (values, token) => {
  return await axios.post(`${process.env.REACT_APP_BASE_URL}/flat`, values, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const putUnitsApi = async (id, values, token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/flat/${id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const putAboutApi = async (id, values, token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/about-us/${id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteUnitsApi = async (id, token) => {
  return await axios.delete(`${process.env.REACT_APP_BASE_URL}/flat/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSingleUnitsApi = async (id, token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/flat/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};