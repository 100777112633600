import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { deleteChatbotApi, getChatbotApi } from "../../service/ChatbotService";
import Actions from "../../common/ActionsButtons/Actions";
import CustomTable from "../../common/CustomTable/CustomTable";
import Modal from "react-responsive-modal";
import AddChatbot from "./AddChatbot";
import VieweChatbot from "./ViewChatbot";
import EditChatbot from "./EditChatbot";
import Swal from "sweetalert2";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";

const Chatbot = () => {
  const [chatbotList, setChatbotList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAllChatbot = async () => {
    setIsLoading(true);
    try {
      const res = await getChatbotApi(profileData.token);
      setChatbotList(res?.data?.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllChatbot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Question",
      selector: (row) => row.question,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Answer",
      selector: (row) => row.answer,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Link",
      selector: (row) => (
        <a href={row.link} target="_blank" rel="noreferrer">
          {row.link ? row.link : "-"}
        </a>
      ),
      sortable: true,
      // right: "true",
      reorder: true,
    },
    {
      id: 5,
      name: "Actions",
      selector: (row) => (
        <Actions
          row={row}
          viewopenModal={viewopenModal}
          edittopenModal={editModel}
          deleteRecord={deleteRecord}
        />
      ),
      sortable: true,
      // right: "true",
      reorder: true,
    },
  ];

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const viewopenModal = () => {
    setViewOpen(true);
  };
  const viewcloseModal = () => {
    setViewOpen(false);
  };
  const editModel = () => {
    setEditOpen(true);
  };
  const closeEditModel = () => {
    setEditOpen(false);
  };

  const deleteRecord = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteChatbotApi(id, profileData.token)
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: res?.data?.msg,
              icon: "success",
            });
            getAllChatbot();
          })
          .catch((err) => {
            ErrorAlert({ title: err?.response?.data?.error });
          });
      }
    });
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="m-4">
            {chatbotList && (
              <CustomTable
                columns={columns}
                data={chatbotList}
                setSearchText={setSearchText}
                searchText={searchText}
                openModal={openModal}
              />
            )}
          </div>
          <Modal open={open} onClose={() => closeModal()} center>
            <h2 className="ms-5">Add Question</h2>
            <AddChatbot closeModal={closeModal} getAllChatbot={getAllChatbot} />
          </Modal>

          <Modal open={viewOpen} onClose={() => viewcloseModal()} center>
            <h2 className="ms-5 me-5">View Question</h2>
            <VieweChatbot closeModal={viewcloseModal} />
          </Modal>

          <Modal open={editOpen} onClose={closeEditModel} center>
            <h2 className="ms-5">Edit Question</h2>
            <EditChatbot
              closeModal={closeEditModel}
              getAllChatbot={getAllChatbot}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default Chatbot;
