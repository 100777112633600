import axios from "axios";

export const getLeaseBooking = async (token, id) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/booking/lease-booking`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": 0,
      },
    }
  );
};

export const postLeaseBooking = async (values, token) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/booking/lease-booking`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const putLeaseBooking = async (id, values, token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/booking/lease-booking/${id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteLeaseBooking = async (id, token) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/booking/lease-booking/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSingleLeaseBooking = async (id, token) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/booking/lease-booking/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": 0,
      },
    }
  );
};
