/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CustomTable from "../../common/CustomTable/CustomTable";
import { useSelector } from "react-redux";
import Loader from "../../common/loader/loader";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/navigation";
import "react-responsive-modal/styles.css";
import { getAllUsers } from "../../service/authService";

const Users = () => {
  const [usersList, setUsersList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  if (location.pathname == "/") {
    navigate(ROUTES.UNIT_DETAILS);
  }

  const getAllUserList = async () => {
    setIsLoading(true);
    try {
      const res = await getAllUsers(profileData.token);
      setUsersList(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUserList();
  }, []);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Full Name",
      selector: (row) => row.full_name,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Phone Number",
      selector: (row) => row.mobile_number,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="m-4">
            {usersList && (
              <CustomTable
                columns={columns}
                data={usersList}
                setSearchText={setSearchText}
                searchText={searchText}
                openModal={() => {}}
                isAdd={true}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Users;
