/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getSingleGalleryApi } from "../../service/GalleryService";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";

const ViewGallery = ({ closeModal }) => {
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({});

  const getSingleGallery = () => {
    getSingleGalleryApi(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        console.log("err", err);
      });
  };
  useEffect(() => {
    getSingleGallery();
  }, []);

  return (
    <Card style={{ width: "100%" }}>
      <Card.Img variant="top" src={list?.image} />
      <Card.Body>
        <Card.Title>
          <strong>Type</strong> : {list?.galleryType?.name}
        </Card.Title>

        <Button variant="primary" onClick={() => closeModal()} className="mt-3">
          Cancel
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ViewGallery;
