import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Enter Valid Email").required("Email Required"),
  password: Yup.string()
    .min(6, "Password should be between 6 characters long")
    .required("Password Required"),
});

export const forgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Enter Valid Email").required("Email Required"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("New Password is required")
    .min(8, "Password should be between 8-15 characters long")
    .max(15, "Password should be between 8-15 characters long"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password does not match")
    .required("Confirm Password is Required"),
});
