import axios from "axios";

export const getContectUsApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/contact-us`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};

export const deleteContectUsApi = async (id, token) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/contact-us/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSingleContectUsApi = async (id, token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/contact-us/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};
