import axios from "axios";

export const getAppartmentApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/appartment`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": 0,
    },
  });
};

export const postAppartmentApi = async (values, token) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/appartment`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const putAppartmentApi = async (id, values, token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/blog/${id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteAppartmentApi = async (id, token) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/appartment/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSingleAppartmentApi = async (id, token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/appartment/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": 0,
    },
  });
};
