import React, { useState } from "react";
import "./forgetPassword.css";
import forgetPassword from "../../assets/forgetPassword.svg";
import "../Login/login.css";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { forgetPasswordApi } from "../../service/authService";
import { ROUTES } from "../../config/navigation";
import { forgetPasswordSchema } from "../../schema/authSchema";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: "",
  };
  const [isLoading, setIsLoading] = useState(false);

  const forgetPasswordSubmit = async (values, action) => {
    setIsLoading(true);
    forgetPasswordApi(values)
      .then((res) => {
        console.log(res);
        navigate(ROUTES.LOGIN);
        SuccessAlert({ title: res?.data?.msg });
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        setIsLoading(false);
      });
  };

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      validationSchema: forgetPasswordSchema,
      initialValues: initialValues,
      onSubmit: (values, action) => {
        forgetPasswordSubmit(values, action);
      },
    });
  return (
    <div className="text-center forget-password-main">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="fpm_inner_block container-fluid">
            <img
              src={forgetPassword}
              alt="forget-password"
              className="forget_img"
            />
            <h2 className="text-center">Forgot Password?</h2>
            <p>Enter email address associated with account.</p>
            <form onSubmit={handleSubmit}>
              <div className="panel-body ">
                <div className="form-group">
                  <input
                    id="email"
                    name="email"
                    placeholder="email address"
                    className="form-control fpm_input"
                    type="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <p className="form-error">{errors.email}</p>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    className="btn border-4 custom_btn"
                    value="Reset Password"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="back-arrow">
            <Link to={ROUTES.LOGIN} className="link-connect">
              <p className="">
                <ArrowBackIcon className="me-2" />
                Back to Login
              </p>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default ForgetPassword;
