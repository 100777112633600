import React, { useEffect, useState } from "react";
import {
  getLeaseUnitFlatIdApi,
  getSingleLeaseUnitApi,
  postLeaseUnitApi,
  putLeaseUnitApi,
} from "../../service/LeaseUnitService";
import { useSelector } from "react-redux";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const FormComponent = ({
  closeModal,
  getAllgetLeaseUnitApiDetails,
  editOpen,
  editModel,
}) => {
  const [flatId, setFlatId] = useState([]);
  const { profileData } = useSelector((state) => state.profileReducer);
  const { id } = useSelector((state) => state.unitDetailsReducer);

  const formik = useFormik({
    initialValues: {
      start_date: new Date(),
      end_date: new Date(),
      flatId: "",
      isLeaseUnit: true,
    },
    onSubmit: async (values) => {
      try {
        if (editOpen) {
          putLeaseUnitApi(id, values, profileData.token)
            .then((res) => {
              SuccessAlert({ title: res?.data?.msg });
              getAllgetLeaseUnitApiDetails();
              editModel();
            })
            .catch((err) => {
              ErrorAlert({ title: err?.response?.data?.error });
            });
        } else {
          const res = await postLeaseUnitApi(values, profileData.token);
          SuccessAlert({ title: res?.data?.msg });
          getAllgetLeaseUnitApiDetails();
          closeModal();
        }
      } catch (err) {
        ErrorAlert({ title: err?.response?.data?.error });
      }
    },
  });

  const getLeaseUnitListing = async () => {
    try {
      const res = await getLeaseUnitFlatIdApi(profileData.token);
      setFlatId(res?.data?.result);
      // Update formik flatId value when flatId data is fetched
      if (res?.data?.result.length > 0) {
        formik.setFieldValue("flatId", res.data.result[0].id);
      }
    } catch (err) {
      ErrorAlert({ title: err?.response?.data?.error });
    }
  };

  useEffect(() => {
    getLeaseUnitListing();
  }, []);

  const getSingleMaintenance = () => {
    getSingleLeaseUnitApi(id, profileData.token)
      .then((res) => {
        const data = res.data.result;
        console.log("data", data?.flatDetails);
        setFlatId([data?.flatDetails]);
        formik.setValues({
          start_date: new Date(data.start_date),
          end_date: new Date(data.end_date),
          flatId: data.flatId,
          isLeaseUnit: data.isLeaseUnit,
        });
      })
      .catch((err) => {
        ErrorAlert({ title: err });
      });
  };

  useEffect(() => {
    if (editOpen) {
      getSingleMaintenance();
    }
  }, [id]);


  console.log(flatId);
  
  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="form-group">
            <div className="comman-grey">
              <p>Start Date</p>
            </div>
            <DatePicker
              name="start_date"
              className="input-box mt-1"
              placeholder="Start Date"
              selected={formik.values.start_date}
              onChange={(date) => formik.setFieldValue("start_date", date)}
              onBlur={formik.handleBlur}
            />
            {formik.errors.start_date && formik.touched.start_date && (
              <p className="text-danger mt-2 mb-3">
                {formik.errors.start_date}
              </p>
            )}
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="form-group">
            <div className="comman-grey">
              <p>End Date</p>
            </div>
            <DatePicker
              name="end_date"
              className="input-box mt-1"
              placeholder="End Date"
              selected={formik.values.end_date}
              onChange={(date) => formik.setFieldValue("end_date", date)}
              onBlur={formik.handleBlur}
            />
            {formik.errors.end_date && formik.touched.end_date && (
              <p className="text-danger mt-2 mb-3">{formik.errors.end_date}</p>
            )}
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
          <div className="form-group">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="flat-select-label">Flat</InputLabel>
                <Select
                  labelId="flat-select-label"
                  id="flat-select"
                  name="flatId"
                  value={formik.values.flatId}
                  label="Flat"
                  onChange={formik.handleChange}
                >
                  {flatId?.map((ele, idx) => (
                    <MenuItem key={idx} value={ele?.id}>
                      {ele?.unit_no}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.errors.flatId && formik.touched.flatId && (
                <p className="text-danger mt-2 mb-3">{formik.errors.flatId}</p>
              )}
            </Box>
          </div>
        </div>

        <div className="mt-3">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <button
            type="button"
            onClick={closeModal}
            className="btn btn-danger ms-3"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
