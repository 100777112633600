import * as Yup from "yup";

export const addBlogSchema = Yup.object().shape({
  title: Yup.string()
    .required("title is required")
    .min(3, "title must be 3 characters at minimum")
    .max(100, "title more then 100 character"),
  author: Yup.string()
    .required("author is required")
    .min(3, "author must be 3 characters at minimum")
    .max(100, "author more then 100 character"),
  description: Yup.string()
    .required("description is required")
    .min(3, "description must be 3 characters at minimum")
    .max(10000, "description more then 10000 character"),
  date: Yup.string().required("date is required"),
});
