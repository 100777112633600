import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import Sidebar from "./common/sidebar/Sidebar";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ForgetPassword from "./pages/ForgotPassword/ForgetPassword";
import UnitDetails from "./pages/UnitDetails/UnitDetails";
import { ROUTES } from "./config/navigation";
import PageNotFound from "./common/pageNotFound/PageNotFound";
import { useEffect, useState } from "react";
import ProtectedRoute from "./common/ProtectedRoute/ProtectedRoute";
import AddUnitDetails from "./pages/UnitDetails/AddUnitDetails";
import Animites from "./pages/Animites/Animites";
import Gallery from "./pages/Gallery/Gallery";
import AboutUs from "./pages/AboutUs/AboutUs";
import Testimonials from "./pages/Testimonials/Testimonials";
import Blogs from "./pages/Blogs/Blogs";
import Maintenance from "./pages/Maintenance/Maintenance";
import ContectUs from "./pages/ContectUs/ContectUs";
import Chatbot from "./pages/Chatbot/Chatbot";
import Booking from "./pages/Booking/Booking";
import Units from "./pages/Units/Units";
import MinimumStay from "./pages/MinimumStay/MinimumStay";
import OneBooking from "./pages/Booking/OneBooking";
import GalleryType from "./pages/GalleryType/GalleryType";
import Profile from "./pages/profile/profile";
import LeaseBooking from "./pages/leaseBooking/LeaseBooking";
import Users from "./pages/Users/Users";
import LeaseUnit from "./pages/LeaseUnit/LeaseUnit";
import Appartment from "./pages/Appartment/Appartment";

function App() {
  const userLogin = localStorage.getItem("loginUser");
  const location = useLocation();
  const [showSideBar, setShowSideBar] = useState(
    userLogin &&
      location.pathname !== ROUTES.FORGET_PASSWORD &&
      location.pathname !== ROUTES.RESET_PASSWORD &&
      location.pathname !== ROUTES.PAGE_NOT_FOUND &&
      location.pathname !== ROUTES.LOGIN
  );
  useEffect(() => {
    setShowSideBar(
      userLogin &&
        location.pathname !== ROUTES.FORGET_PASSWORD &&
        location.pathname !== ROUTES.RESET_PASSWORD &&
        location.pathname !== ROUTES.PAGE_NOT_FOUND &&
        location.pathname !== ROUTES.LOGIN
    );
  }, [location.pathname, userLogin]);
  return (
    <>
      {showSideBar && <Sidebar />}
      <div>
        <Routes>
          <Route
            path="/"
            element={<ProtectedRoute element={<UnitDetails />} />}
          />
          <Route path={ROUTES.LOGIN} element={<Login />}></Route>
          <Route
            path={ROUTES.FORGET_PASSWORD}
            element={<ForgetPassword />}
          ></Route>
          <Route
            path={ROUTES.RESET_PASSWORD}
            element={<ResetPassword />}
          ></Route>
          <Route
            path={ROUTES.USERS}
            element={<ProtectedRoute element={<Users />} />}
          />
          <Route
            path={ROUTES.UNIT_DETAILS}
            element={<ProtectedRoute element={<UnitDetails />} />}
          />
          <Route
            path={ROUTES.UNIT_DETAILS_ADD}
            element={<ProtectedRoute element={<AddUnitDetails />} />}
          />
          <Route
            path={ROUTES.ANUMITES}
            element={<ProtectedRoute element={<Animites />} />}
          />
          <Route
            path={ROUTES.GALLERYTYPE}
            element={<ProtectedRoute element={<GalleryType />} />}
          />
          <Route
            path={ROUTES.GALLERY}
            element={<ProtectedRoute element={<Gallery />} />}
          />
          <Route
            path={ROUTES.ABOUTUS}
            element={<ProtectedRoute element={<AboutUs />} />}
          />
          <Route
            path={ROUTES.TESTIMONIALS}
            element={<ProtectedRoute element={<Testimonials />} />}
          />
          <Route
            path={ROUTES.BLOGS}
            element={<ProtectedRoute element={<Blogs />} />}
          />
          <Route
            path={ROUTES.APPARTMENT}
            element={<ProtectedRoute element={<Appartment />} />}
          />
          <Route
            path={ROUTES.MAINTENANCE}
            element={<ProtectedRoute element={<Maintenance />} />}
          />
          <Route
            path={ROUTES.CONTECTUS}
            element={<ProtectedRoute element={<ContectUs />} />}
          />
          <Route
            path={ROUTES.CHATBOT}
            element={<ProtectedRoute element={<Chatbot />} />}
          />
          <Route
            path={ROUTES.BOOKINGS}
            element={<ProtectedRoute element={<Booking />} />}
          />
          <Route
            path={ROUTES.UNITS}
            element={<ProtectedRoute element={<Units />} />}
          />
          <Route
            path={ROUTES.MINUMUMSTAY}
            element={<ProtectedRoute element={<MinimumStay />} />}
          />
          <Route
            path={ROUTES.ONEBOOKING}
            element={<ProtectedRoute element={<OneBooking />} />}
          />
          <Route
            path={ROUTES.PROFILE}
            element={<ProtectedRoute element={<Profile />} />}
          />
          <Route
            path={ROUTES.LEASE_BOOKING}
            element={<ProtectedRoute element={<LeaseBooking />} />}
          />
          <Route
            path={ROUTES.LEASE_UNIT}
            element={<ProtectedRoute element={<LeaseUnit />} />}
          />
          <Route path="/*" element={<PageNotFound />}></Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
