/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import { getSingleUnitDetailsApi } from "../../service/unitDetailsService";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";

const ViewUnitDetails = ({ closeModal, viewList }) => {
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({});
  const getSingleUnit = () => {
    getSingleUnitDetailsApi(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        console.log("err", err);
      });
  };
  useEffect(() => {
    getSingleUnit();
  }, []);
  return (
    <div>
      <Card style={{ width: "100%" }}>
        <Card.Title>
          <strong>Unit Details Images:</strong>
          <div className="preview-container d-flex align-items-center gap-2 mt-3">
            {list?.images?.map((image, index) => (
              <div key={index} className="preview-container">
                <img
                  src={image}
                  alt={`Preview ${index}`}
                  className="image-preview"
                />
              </div>
            ))}
          </div>
        </Card.Title>
        <Card.Title>
          <strong>Unit Details Thumbnail:</strong>
          {list?.thumbnail && list?.thumbnail.length > 0 && (
            <div className="preview-container">
              <Card.Img
                className="floorPlan"
                variant="top"
                src={list?.thumbnail}
              />
            </div>
          )}
        </Card.Title>
        <Card.Body>
          <Card.Title>
            <strong>Title</strong> : {list?.title}
          </Card.Title>
          <Card.Title>
            <strong>Description</strong> : {list?.description}
          </Card.Title>
          <Card.Title>
            <strong>Features</strong> : {list?.features?.join(", ")}
          </Card.Title>
          <Button
            variant="primary"
            onClick={() => closeModal()}
            className="mt-3"
          >
            Cancel
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ViewUnitDetails;
