import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";
import { putAboutApi } from "../../service/UnitsService";

const AboutUs = () => {
  const { profileData } = useSelector((state) => state.profileReducer);
  const [details, setDetials] = useState({
    slogan_image: "",
    vision_image: "",
    mission_image: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const getAboutData = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/about-us`,
        {
          headers: {
            Authorization: `Bearer ${profileData.token}`,
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setDetials(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAboutData();
  }, []);

  const [images, setImages] = useState(details.slogan_image || []);
  const [thumbnail, setThumbnail] = useState(details.vision_image || []);
  const [mission, setMission] = useState(details.mission_image || []);

  const [imagesBuffer, setImagesBuffer] = useState([]);
  const [thumbnailBuffer, setThumbnailBuffer] = useState([]);
  const [missionBuffer, setMissionBuffer] = useState([]);

  useEffect(() => {
    setImages(details.slogan_image || []);
    setThumbnail(details.vision_image || []);
    setMission(details.mission_image || []);
  }, [details]);

  const handleFileChange = (
    e,
    setBuffer,
    setImageState,
    isThumbnail = false,
    isMission = false
  ) => {
    const selectedImages = Array.from(e.target.files);
    setBuffer([...selectedImages]);

    const imagePreviews = selectedImages.map((image) =>
      URL.createObjectURL(image)
    );

    if (selectedImages.length === 1) {
      setImageState((prevState) => [...prevState, ...imagePreviews]);
    } else {
      setImageState(imagePreviews);
    }

    // Update thumbnail only if isThumbnail is true
    if (isThumbnail && selectedImages.length === 1) {
      setThumbnail(imagePreviews);
    }

    // Update mission only if isMission is true
    if (isMission && selectedImages.length === 1) {
      setMission(imagePreviews);
    }
  };

  const removeItem = (index, array, setArray, buffer, setBuffer, e) => {
    e.preventDefault();
    const updatedArray = [...array];
    updatedArray.splice(index, 1);
    setArray(updatedArray);

    const updatedBuffer = [...buffer];
    updatedBuffer.splice(index, 1);
    setBuffer(updatedBuffer);
  };

  const uploadImages = async (imageBuffer) => {
    try {
      const uploadedUrls = await Promise.all(
        imageBuffer.map(async (image) => {
          const formData = new FormData();
          formData.append("image", image);

          try {
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/upload`,
              formData
            );

            return res.data.result;
          } catch (error) {
            console.error("Error uploading image:", error);
            throw error;
          }
        })
      );
      return uploadedUrls;
    } catch (error) {
      console.error("Error uploading images:", error);
      return [];
    }
  };

  const uploadThumbnail = async (thumbnailBuffer) => {
    try {
      const uploadedUrls = await Promise.all(
        thumbnailBuffer.map(async (image) => {
          const formData = new FormData();
          formData.append("image", image);

          try {
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/upload`,
              formData
            );

            return res.data.result;
          } catch (error) {
            console.error("Error uploading thumbnail:", error);
            throw error;
          }
        })
      );
      return uploadedUrls;
    } catch (error) {
      console.error("Error uploading thumbnails:", error);
      return [];
    }
  };

  const uploadMission = async (thumbnailBuffer) => {
    try {
      const uploadedUrls = await Promise.all(
        thumbnailBuffer.map(async (image) => {
          const formData = new FormData();
          formData.append("image", image);

          try {
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/upload`,
              formData
            );

            return res.data.result;
          } catch (error) {
            console.error("Error uploading thumbnail:", error);
            throw error;
          }
        })
      );
      return uploadedUrls;
    } catch (error) {
      console.error("Error uploading thumbnails:", error);
      return [];
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      // Upload images first
      const uploadedImages = await uploadImages(imagesBuffer);
      const uploadedThumbnails = await uploadThumbnail(thumbnailBuffer);
      const uploadedMission = await uploadMission(missionBuffer);

      // Build the updatedValues object with conditional spreading
      const updatedValues = {
        ...values,
        ...(imagesBuffer.length > 0 && {
          slogan_image: uploadedImages || details[0]?.slogan_image,
        }),
        ...(thumbnailBuffer.length > 0 && {
          vision_image: uploadedThumbnails || details[0]?.vision_image,
        }),
        ...(missionBuffer.length > 0 && {
          mission_image: uploadedMission || details[0]?.mission_image,
        }),
      };

      const res = await putAboutApi(1, updatedValues, profileData.token);

      getAboutData();

      SuccessAlert({ title: res.data.msg });
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  console.log(isLoading);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <Formik
            initialValues={details}
            enableReinitialize
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              onFinish(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form className="p-4 aboutus_form">
                <div className="row_block">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>Slogan Image</p>
                        </div>
                        <input
                          type="file"
                          name="images"
                          multiple
                          className="input-box mt-1"
                          placeholder="Upload Images"
                          onChange={(e) =>
                            handleFileChange(e, setImagesBuffer, setImages)
                          }
                        />
                      </div>
                      <div className="preview-container d-flex align-items-center gap-2 mt-3">
                        {images &&
                          images.length > 0 &&
                          images.map((image, index) => (
                            <div key={index} className="preview-container">
                              <img
                                src={image}
                                alt={`Preview ${index}`}
                                className="image-preview"
                              />
                              <button
                                className="remove-button"
                                onClick={(e) =>
                                  removeItem(
                                    index,
                                    images,
                                    setImages,
                                    imagesBuffer,
                                    setImagesBuffer,
                                    e
                                  )
                                }
                              >
                                ❌
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>Vission Image</p>
                        </div>
                        <input
                          type="file"
                          name="images"
                          className="input-box mt-1"
                          placeholder="Upload Images"
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              setThumbnailBuffer,
                              setThumbnail,
                              true, // Set to true for thumbnail
                              false // Set to false for not mission image
                            )
                          }
                        />
                      </div>
                      <div className="preview-container d-flex align-items-center gap-2 mt-3">
                        {thumbnail &&
                          thumbnail.length > 0 &&
                          thumbnail.map((image, index) => (
                            <div key={index} className="preview-container">
                              <img
                                src={image}
                                alt={`Preview ${index}`}
                                className="image-preview"
                              />
                              <button
                                className="remove-button"
                                onClick={(e) =>
                                  removeItem(
                                    index,
                                    thumbnail,
                                    setThumbnail,
                                    thumbnailBuffer,
                                    setThumbnailBuffer,
                                    e
                                  )
                                }
                              >
                                ❌
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>Mission Image</p>
                        </div>
                        <input
                          type="file"
                          name="images"
                          className="input-box mt-1"
                          placeholder="Upload Images"
                          onChange={(e) =>
                            handleFileChange(
                              e,
                              setMissionBuffer,
                              setMission,
                              false, // Set to false for not thumbnail
                              true
                            )
                          }
                        />
                      </div>

                      <div className="preview-container d-flex align-items-center gap-2 mt-3">
                        {mission &&
                          mission.length > 0 &&
                          mission.map((image, index) => (
                            <div key={index} className="preview-container">
                              <img
                                src={image}
                                alt={`Preview ${index}`}
                                className="image-preview"
                              />
                              <button
                                className="remove-button"
                                onClick={(e) =>
                                  removeItem(
                                    index,
                                    mission,
                                    setMission,
                                    missionBuffer,
                                    setMissionBuffer,
                                    e
                                  )
                                }
                              >
                                ❌
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>mission</p>
                        </div>
                        <textarea
                          type="text"
                          name="mission"
                          className="input-box mt-1 w-100 text-black"
                          placeholder="Title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mission}
                          style={{ height: "200px" }}
                        />
                      </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>vision</p>
                        </div>
                        <textarea
                          type="text"
                          className="input-box mt-1 w-100 text-black"
                          placeholder="Title"
                          name="vision"
                          style={{ height: "200px" }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vision}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>slogan 1 name</p>
                        </div>
                        <input
                          type="text"
                          className="input-box mt-1 w-100 text-black"
                          placeholder="slogan 1 name"
                          name="slogan1_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.slogan1_name}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>slogan 1 Description</p>
                        </div>
                        <input
                          type="text"
                          className="input-box mt-1 w-100 text-black"
                          placeholder="slogan 1 Description"
                          name="slogan1_desc"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.slogan1_desc}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>slogan 2 name</p>
                        </div>
                        <input
                          type="text"
                          className="input-box mt-1 w-100 text-black"
                          placeholder="slogan 1 name"
                          name="slogan2_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.slogan2_name}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>slogan 2 Description</p>
                        </div>
                        <input
                          type="text"
                          className="input-box mt-1 w-100 text-black"
                          placeholder="slogan 2 Description"
                          name="slogan2_desc"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.slogan2_desc}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>slogan 3 name</p>
                        </div>
                        <input
                          type="text"
                          className="input-box mt-1 w-100 text-black"
                          placeholder="slogan 3 name"
                          name="slogan3_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.slogan3_name}
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-3">
                      <div className="form-group">
                        <div className="comman-grey">
                          <p>slogan 3 Description</p>
                        </div>
                        <input
                          type="text"
                          className="input-box mt-1 w-100 text-black"
                          placeholder="slogan 3 Description"
                          name="slogan3_desc"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.slogan3_desc}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 mt-3 text-center">
                    <div className="mt-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};

export default AboutUs;
