import axios from "axios";

export const getGalleryTypeApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/gallery-type`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning" : 0
    },
  });
};

export const postGalleryTypeApi = async (values, token) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/gallery-type`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const putGalleryTypeApi = async (id, values, token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/gallery-type/${id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteGalleryTypeApi = async (id, token) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/gallery-type/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSingleGalleryTypeApi = async (id, token) => {
  return await axios.get(
    `${process.env.REACT_APP_BASE_URL}/gallery-type/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning" : 0
      },
    }
  );
};
