/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSingleAnimitesApi } from "../../service/AnimitesService";
import { Button, Card } from "react-bootstrap";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";

const ViewAnimites = ({ closeModal, viewList }) => {
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({});
  const getSingleUnit = () => {
    getSingleAnimitesApi(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };
  useEffect(() => {
    getSingleUnit();
  }, []);
  return (
    <div>
      <Card style={{ width: "100%", "text-align": "justify" }}>
        <Card.Img variant="top" className="custom1" src={list?.image} />
        <Card.Body>
          <Card.Title>
            <strong>Title</strong> : {list?.title}
          </Card.Title>
          <Card.Title>
            <strong>Description</strong> : {list?.description}
          </Card.Title>
          <Button
            variant="primary"
            onClick={() => closeModal()}
            className="mt-3"
          >
            Cancel
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ViewAnimites;
