/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUnitsApi } from "../../service/UnitsService";
import Actions from "../../common/ActionsButtons/Actions";
import CustomTable from "../../common/CustomTable/CustomTable";
import Modal from "react-responsive-modal";
import { NavLink } from "react-router-dom";
import ViewUnits from "./ViewUnits";
import EditUnits from "./EditUnits";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";
const Units = () => {
  const [unitList, setUnitList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [viewOpen, setViewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [floorType, setFloorType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const getAllUnit = async () => {
    setIsLoading(true);
    try {
      const res = await getUnitsApi(profileData.token, floorType);
      setUnitList(res?.data?.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUnit();
  }, [floorType]);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Unit",
      selector: (row) => row.unit_no,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Unit type",
      selector: (row) => row?.unitType?.name,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Guests",
      selector: (row) => row.guests,
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "Baths",
      selector: (row) => row.bathrooms,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Beds",
      selector: (row) => row.bedrooms,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: "Security Deposit",
      selector: (row) => row.deposit,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "Actions",
      selector: (row) => (
        <Actions
          row={row}
          viewopenModal={viewopenModal}
          edittopenModal={editModel}
          hiddenDelete="DeleteModel"
        />
      ),
      sortable: true,
      reorder: true,
    },
  ];

  const viewopenModal = () => {
    setViewOpen(true);
  };
  const viewcloseModal = () => {
    setViewOpen(false);
  };
  const editModel = () => {
    setEditOpen(true);
  };
  const closeEditModel = () => {
    setEditOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <ul
            className="nav nav-pills"
            style={{ marginLeft: "1.5rem", marginTop: "-2rem" }}
          >
            <li className="nav-item mt-5 me-2 ms-2 d-flex">
              <NavLink
                className={`nav-link me-2 ${
                  floorType === 1 ? "activeTab" : ""
                }`}
                onClick={() => setFloorType(1)}
              >
                Floor 1
              </NavLink>
              <NavLink
                className={`nav-link me-2 ${
                  floorType === 2 ? "activeTab" : ""
                }`}
                onClick={() => setFloorType(2)}
              >
                Floor 2
              </NavLink>
              <NavLink
                className={`nav-link ${floorType === 3 ? "activeTab" : ""}`}
                onClick={() => setFloorType(3)}
              >
                Floor 3
              </NavLink>
            </li>
          </ul>
          <div className="m-4">
            {unitList && (
              <CustomTable
                columns={columns}
                data={unitList}
                setSearchText={setSearchText}
                searchText={searchText}
                hideModel="add-model"
              />
            )}
          </div>
          <Modal open={viewOpen} onClose={viewcloseModal} center>
            <h2 className="ms-5 me-5">View Unit </h2>
            <ViewUnits closeModal={viewcloseModal} />
          </Modal>

          <Modal open={editOpen} onClose={closeEditModel} center>
            <h2 className="ms-5">Edit Unit</h2>
            <EditUnits closeModal={closeEditModel} getAllUnit={getAllUnit} />
          </Modal>
        </div>
      )}
    </>
  );
};

export default Units;
