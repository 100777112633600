/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBookingApi } from "../../service/BookingService";
import CustomTable from "../../common/CustomTable/CustomTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";
import moment from "moment";

const RejectedBookings = (props) => {
  const [bookingList, setBookingList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [isLoading, setIsLoading] = useState(false);

  const getAllBooking = async () => {
    setIsLoading(true);
    try {
      const res = await getBookingApi(profileData.token, "Rejected");
      setBookingList(res?.data?.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBooking();
  }, []);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Name",
      selector: (row) => row.full_name,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Number",
      selector: (row) => row.number,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("DD/MM/YYYY"),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "End Date",
      selector: (row) => moment(row.end_date).format("DD/MM/YYYY"),
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 6,
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 7,
      name: "Deposit",
      selector: (row) => row.deposit,
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 8,
      name: "Reason",
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "view",
      selector: (row) => (
        <div>
          <p
            className="m-1 cp viewModel"
            onClick={() => {
              props.setIsOneBooking({ id: row.id, view: true });
              props.setIsApiCall(true);
            }}
          >
            <VisibilityIcon />
          </p>
        </div>
      ),
      sortable: true,
      right: "true",
      reorder: true,
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="m-4">
          {bookingList && (
            <CustomTable
              columns={columns}
              data={bookingList}
              setSearchText={setSearchText}
              searchText={searchText}
              hideModel="add-model"
            />
          )}
        </div>
      )}
    </>
  );
};

export default RejectedBookings;
