import * as Yup from "yup";

export const addQuestionSchema = Yup.object().shape({
  question: Yup.string()
    .required("question is required")
    .max(1000, "question more then 100 character"),
  answer: Yup.string()
    .required("answer is required")
    .max(20000, "answer more then 10000 character"),
  link: Yup.string().url("Link must be a valid URL"),
});
