/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getSingleUnitsApi, putUnitsApi } from "../../service/UnitsService";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import axios from "axios";
import INLoader from "../../common/loader/inLoader";
import { addUnitSchema } from "../../schema/unitSchema";
import Image from "../../common/image drag/imageList";

const EditUnits = ({ closeModal, getAllUnit }) => {
  const { profileData } = useSelector((state) => state.profileReducer);
  const { id } = useSelector((state) => state.unitDetailsReducer);

  //   const { profileData = {} } = useSelector((state) => state.profileReducer);
  // const { id } = useSelector((state) => state.unitlistReducer) || {};
  const unitTypeList = [
    { id: 1, name: "1 Bed / 1 Bath" },
    { id: 2, name: "2 Bed / 2 Bath" },
    { id: 3, name: "3 Bed / 3 Bath" },
    { id: 4, name: "Deluxe 1 Bed / 2 Bath / Home Office" },
    { id: 5, name: "Deluxe 2 Bed / 3 Bath / Home Office" },
  ];
  const [list, setList] = useState({
    bathrooms: 0,
    bedrooms: 0,
    cleaning_fees: 0,
    deposit: 0,
    description: "",
    floor: 1,
    floorplan_image: "",
    guests: 0,
    images: "",
    price: 0,
    unit_no: 101,
    unit_type: 1,
  });
  const getSingleUnit = () => {
    getSingleUnitsApi(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };
  useEffect(() => {
    getSingleUnit();
  }, []);

  const [images, setImages] = useState(list.images || []);
  const [floorplan_image, setfloorplanimage] = useState(
    list.floorplan_image || []
  );
  const [imagesBuffer, setImagesBuffer] = useState([]);
  const [floorplanBuffer, setfloorplanBuffer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setImages(list.images || []);
    setfloorplanimage(list.floorplan_image || []);
  }, [list]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const dragIndex = e.dataTransfer.getData("text/plain");
    const newImages = [...images];
    const dragImage = newImages[dragIndex];
    newImages.splice(dragIndex, 1);
    newImages.splice(index, 0, dragImage);
    setImages(newImages);
  };

  const handleFileChange = (
    e,
    setBuffer,
    setImageState,
    isThumbnail = false
  ) => {
    const selectedImages = Array.from(e.target.files);
    setBuffer(selectedImages);

    const imagePreviews = selectedImages.map((image) =>
      URL.createObjectURL(image)
    );
    if (selectedImages.length === 1) {
      setImageState((prevState) => [...prevState, ...imagePreviews]);
    } else {
      setImageState(imagePreviews);
    }

    if (isThumbnail && selectedImages.length === 1) {
      setfloorplanimage(imagePreviews); // Replace the entire array with the new thumbnail
    }
  };

  const removeItem = (index, array, setArray, buffer, setBuffer, e) => {
    e.preventDefault();
    const updatedArray = [...array];
    updatedArray.splice(index, 1);
    setArray(updatedArray);

    const updatedBuffer = [...buffer];
    updatedBuffer.splice(index, 1);
    setBuffer(updatedBuffer);
  };

  const uploadImages = async (imageBuffer) => {
    try {
      setIsLoading(true);
      const uploadedUrls = await Promise.all(
        imageBuffer.map(async (image) => {
          const formData = new FormData();
          formData.append("image", image);

          try {
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/upload`,
              formData
            );

            return res.data.result;
          } catch (error) {
            console.error("Error uploading image:", error);
            throw error;
          }
        })
      );
      setIsLoading(false);
      return uploadedUrls;
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading images:", error);
      return [];
    }
  };

  const uploadFloorplan = async (floorplanBuffer) => {
    try {
      setIsLoading(true);
      const uploadedUrls = await Promise.all(
        floorplanBuffer.map(async (image) => {
          const formData = new FormData();
          formData.append("image", image);

          try {
            const res = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/upload`,
              formData
            );

            return res.data.result;
          } catch (error) {
            console.error("Error uploading floorplan_image:", error);
            throw error;
          }
        })
      );
      setIsLoading(false);
      return uploadedUrls;
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading floorplanImages:", error);
      return [];
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const uploadedImages = await uploadImages(imagesBuffer);
      const uploadedfloorplanImages = await uploadFloorplan(floorplanBuffer);

      const filteredImages = images.filter(
        (image) => !image.startsWith("blob:")
      );
      const filteredfloorplanImages = floorplan_image.filter(
        (image) => !image.startsWith("blob:")
      );

      const updatedImages = [...filteredImages, ...uploadedImages];
      const updatedfloorplanImages = [
        ...filteredfloorplanImages,
        ...uploadedfloorplanImages,
      ];

      const res = await putUnitsApi(
        id,
        {
          ...values,
          images: updatedImages,
          floorplan_image: updatedfloorplanImages,
        },
        profileData.token
      );
      getAllUnit();
      closeModal();

      SuccessAlert({ title: res.data.msg });
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={list}
      enableReinitialize
      validationSchema={addUnitSchema}
      onSubmit={(values) => {
        onFinish(values);
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <div className="d-flex ms-5">
          <form className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Bath Rooms</p>
                </div>
                <input
                  type="text"
                  name="bathrooms"
                  className="input-box mt-1"
                  placeholder="bathrooms"
                  onChange={handleChange}
                  value={values.bathrooms}
                />
                {errors.bathrooms && (
                  <p className="text-danger mt-2 mb-3">{errors.bathrooms}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Bed Rooms</p>
                </div>
                <input
                  type="text"
                  name="bedrooms"
                  className="input-box mt-1"
                  placeholder="bedrooms"
                  onChange={handleChange}
                  value={values.bedrooms}
                />
                {errors.bedrooms && (
                  <p className="text-danger mt-2 mb-3">{errors.bedrooms}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Guests</p>
                </div>
                <input
                  type="text"
                  name="guests"
                  className="input-box mt-1"
                  placeholder="guests"
                  onChange={handleChange}
                  value={values.guests}
                />
                {errors.guests && (
                  <p className="text-danger mt-2 mb-3">{errors.guests}</p>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group mt-3">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Unit Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="unit_type"
                      value={values.unit_type}
                      label="Unit Type"
                      onChange={handleChange}
                    >
                      {unitTypeList &&
                        unitTypeList?.map((ele, idx) => (
                          <MenuItem key={idx} value={ele?.id}>
                            {ele?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Cleaning Fees</p>
                </div>
                <input
                  type="text"
                  name="cleaning_fees"
                  className="input-box mt-1"
                  placeholder="cleaning_fees"
                  onChange={handleChange}
                  value={values.cleaning_fees}
                />
                {errors.cleaning_fees && (
                  <p className="text-danger mt-2 mb-3">
                    {errors.cleaning_fees}
                  </p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Price</p>
                </div>
                <input
                  type="text"
                  name="price"
                  className="input-box mt-1"
                  placeholder="price"
                  onChange={handleChange}
                  value={values.price}
                />
                {errors.price && (
                  <p className="text-danger mt-2 mb-3">{errors.price}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Security Deposit</p>
                </div>
                <input
                  type="text"
                  name="deposit"
                  className="input-box mt-1"
                  placeholder="deposit"
                  onChange={handleChange}
                  value={values.deposit}
                />
                {errors.deposit && (
                  <p className="text-danger mt-2 mb-3">{errors.deposit}</p>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Description</p>
                </div>
                <input
                  type="text"
                  className="input-box mt-1"
                  placeholder="Description"
                  name="description"
                  onChange={handleChange}
                  value={values.description}
                />
                {errors.description && (
                  <p className="text-danger mt-2 mb-3">{errors.description}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Images</p>
                </div>
                <input
                  type="file"
                  name="images"
                  multiple
                  className="input-box mt-1"
                  placeholder="Upload Images"
                  onChange={(e) =>
                    handleFileChange(e, setImagesBuffer, setImages)
                  }
                />
              </div>
              <div className="preview-container d-flex align-items-center gap-2 mt-3">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {images.map((src, index) => (
                    <div key={index} className="preview-container">
                      <Image
                        key={index}
                        src={src}
                        className="image-preview"
                        index={index}
                        handleDragStart={handleDragStart}
                        handleDragOver={handleDragOver}
                        handleDrop={handleDrop}
                      />
                      <button
                        className="remove-button"
                        onClick={(e) =>
                          removeItem(
                            index,
                            images,
                            setImages,
                            imagesBuffer,
                            setImagesBuffer,
                            e
                          )
                        }
                      >
                        ❌
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Floorplan Image</p>
                </div>
                <input
                  type="file"
                  name="images"
                  multiple
                  className="input-box mt-1"
                  placeholder="Upload Images"
                  onChange={(e) =>
                    handleFileChange(
                      e,
                      setfloorplanBuffer,
                      setfloorplanimage,
                      true
                    )
                  }
                />
              </div>
              {floorplan_image && floorplan_image.length > 0 && (
                <div className="preview-container d-flex align-items-center gap-2 mt-3">
                  {floorplan_image.map((image, index) => (
                    <div key={index} className="preview-container">
                      <img
                        src={image}
                        alt={`Preview ${index}`}
                        className="image-preview"
                      />
                      <button
                        className="remove-button"
                        onClick={(e) =>
                          removeItem(
                            index,
                            floorplan_image,
                            setfloorplanimage,
                            floorplanBuffer,
                            setfloorplanBuffer,
                            e
                          )
                        }
                      >
                        ❌
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {isLoading ? (
              <INLoader />
            ) : (
              <div className="mt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  className="btn btn-danger ms-3"
                >
                  Cancel
                </button>
              </div>
            )}
          </form>
        </div>
      )}
    </Formik>
  );
};

export default EditUnits;
