import * as Yup from "yup";

export const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Password must be at least 8 characters long"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please confirm your new password"),
});

export const profileUpdateSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  full_name: Yup.string().required("Full name is required"),
  mobile_number: Yup.string().required("Mobile number is required"),
});
