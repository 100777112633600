/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSingleUnitsApi } from "../../service/UnitsService";
import { Button, Card } from "react-bootstrap";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";

const ViewUnits = ({ closeModal }) => {
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({});
  const getSingleUnit = () => {
    getSingleUnitsApi(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        console.log("err", err);
      });
  };
  useEffect(() => {
    getSingleUnit();
  }, []);

  return (
    <div>
      <Card className="custom-block1">
        <div>
          <Card.Title>
            <strong>Unit Images</strong> :{" "}
            <div className="preview-container d-flex align-items-center gap-2 mt-3">
              {list?.images?.map((image, index) => (
                <div key={index} className="preview-container">
                  <img
                    src={image}
                    alt={`Preview ${index}`}
                    className="image-preview"
                  />
                </div>
              ))}
            </div>
          </Card.Title>
        </div>
        <div className="d-flex">
          <Card.Title>
            <strong
              className="d-block text-left my-2"
              style={{ "text-align": "left", "padding-left": "12px" }}
            >
              Floor Plan :
            </strong>{" "}
            {list?.floorplan_image && list?.floorplan_image.length > 0 && (
              <div className="preview-container">
                <Card.Img
                  className="floorPlan"
                  variant="top"
                  src={list?.floorplan_image[0]}
                />
              </div>
            )}
          </Card.Title>
          <Card.Body style={{ "text-align": "left" }}>
            <Card.Title>
              <strong>Unit Type</strong> : {list?.unitType?.name}
            </Card.Title>
            <Card.Title>
              <strong>Unit No</strong> : {list?.unit_no}
            </Card.Title>
            <Card.Title>
              <strong>Price</strong> : {list?.price}
            </Card.Title>
            <Card.Title>
              <strong>Bath Rooms</strong> : {list?.bathrooms}
            </Card.Title>
            <Card.Title>
              <strong>Bed Rooms</strong> : {list?.bedrooms}
            </Card.Title>
            <Card.Title>
              <strong>Guests</strong> : {list?.guests}
            </Card.Title>
            <Card.Title>
              <strong>Cleaning Fees</strong> : {list?.cleaning_fees}
            </Card.Title>
            <Card.Title>
              <strong>Deposit</strong> : {list?.deposit}
            </Card.Title>
            <Card.Title>
              <strong>Description</strong> : {list?.description}
            </Card.Title>
            <Button
              variant="primary"
              onClick={() => closeModal()}
              className="mt-3"
            >
              Cancel
            </Button>
          </Card.Body>
        </div>
      </Card>
    </div>
  );
};

export default ViewUnits;
