import React from "react";
import DataTable from "react-data-table-component";
import "./customTable.css";
import AddIcon from "@mui/icons-material/Add";

const paginationComponentOptions = {
  selectAllRowsItem: true,
  selectAllRowsItemText: "ALL",
};

const CustomTable = (props) => {
  const { openModal, hideModel, saveOrder, isAdd } = props;

  const titleWithSearch = () => {
    return (
      <div className={`top-bar ${hideModel || ""}`}>
        <div className="w-50 ">{/* <h3>Unit Details CRUD</h3> */}</div>
        <div className="custom-btn" style={{ display: "flex", justifyContent: "space-between" }}>
          {!isAdd && <button
            className={`custom_btn btn d-flex align-items-center gap-1 h-40 `}
            onClick={() => openModal()}
          >
            <AddIcon />
            <span>Add </span>
          </button>}

          {saveOrder && (
            <button
            style={{marginLeft: "5px"}}
              className={`custom_btn btn d-flex align-items-center gap-1 h-40 `}
              onClick={() => saveOrder()}
            >
              <span>Save Order </span>
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="common-table">
      <DataTable
        title={hideModel === "add-model" ? null : titleWithSearch()}
        columns={props.columns}
        data={props.data}
        defaultSortFieldId={1}
        pagination
        paginationComponentOptions={paginationComponentOptions}
      />
    </div>
  );
};

export default CustomTable;
