/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import axios from "axios";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import Loader from "../../common/loader/loader";
import {
  changePasswordSchema,
  profileUpdateSchema,
} from "../../schema/profileSchema";

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0); // Track active tab, defaulting to the first tab (index 0)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state.profileReducer);
  const initialValue = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const handleTabClick = (index) => {
    setActiveTab(index); // Update active tab when clicked
  };

  const changePassword = async (values) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/change-password`,
        values,
        {
          headers: {
            Authorization: `Bearer ${profileData.token}`,
          },
        }
      );

      if (res.data.code === 200) {
        SuccessAlert({ title: res.data.msg });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const [selectedImage, setSelectedImage] = useState("/images/profile.jpeg");
  const [userData, setUserData] = useState({});

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    setIsLoading(true);

    let formData = new FormData();
    formData.append("image", file);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/upload`,
        formData
      );
      setSelectedImage(res.data.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const userProfile = async (values) => {
    setIsLoading(true); // Set isLoading to true before making the API request
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/admin/profile`,
        { ...values, profile_pic: selectedImage },
        {
          headers: {
            Authorization: `Bearer ${profileData.token}`,
          },
        }
      );

      if (res.data.code === 200) {
        SuccessAlert({ title: res.data.msg });
        const userData = JSON.parse(localStorage.getItem("loginUser"));
        userData["full_name"] = values.full_name;
        userData["profile_pic"] = values.profile_pic;
        localStorage.setItem("loginUser", JSON.stringify(userData));
        getProfile();
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  const getProfile = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/profile`,
        {
          headers: {
            Authorization: `Bearer ${profileData.token}`,
            "ngrok-skip-browser-warning": 0,
          },
        }
      );

      setUserData(res.data.result);
      if (res.data.result.profile_pic)
        setSelectedImage(res.data.result.profile_pic);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/login");
      }
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <ul
            className="nav nav-pills"
            style={{
              marginLeft: "1.5rem",
              marginTop: "-2rem",
              marginBottom: "2rem",
            }}
          >
            <li
              className={`nav-item mt-5 me-2 ms-2 ${
                activeTab === 0 ? "active" : ""
              }`}
            >
              <NavLink
                className={`nav-link ${activeTab === 0 ? "activeTab" : ""}`}
                onClick={() => handleTabClick(0)}
              >
                Profile View
              </NavLink>
            </li>
            <li
              className={`nav-item mt-5 me-2 ms-2 ${
                activeTab === 1 ? "active" : ""
              }`}
            >
              <NavLink
                className={`nav-link ${activeTab === 1 ? "activeTab" : ""}`}
                onClick={() => handleTabClick(1)}
              >
                Change Password
              </NavLink>
            </li>
          </ul>
          {activeTab === 0 && (
            <div className="col-span-12 lg:col-span-9">
              <div className="payment-info m-4">
                <div className="comman-midium-blue">
                  <p>Profile Information</p>
                </div>

                <div className="top">
                  <div className=" d-flex aling-items-center justify-content-center">
                    <div className="image relative">
                      <img
                        src={selectedImage}
                        alt="No_Image"
                        className="edit-image"
                      />
                      <label
                        htmlFor="fileInput"
                        className="comman-bg profile-edit absolute bottom-0 right-0 cursor"
                      >
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*"
                          className="hidden mt-2"
                          onChange={handleFileInputChange}
                        />
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.4499 0.0349582C12.3267 0.0529662 12.0806 0.117687 11.9029 0.178868C11.1973 0.421745 11.4387 0.199339 6.05302 5.56834C3.34415 8.26884 1.09311 10.526 1.05076 10.5842C0.949424 10.7235 0.00595377 15.0237 0.000328009 15.3719C-0.00267753 15.5569 0.0143539 15.6336 0.0770849 15.7182C0.198347 15.8816 0.395441 16 0.546296 16C0.689869 16 5.1326 15.062 5.29648 14.9971C5.35042 14.9757 7.6334 12.7209 10.3697 9.98629C13.9622 6.39616 15.3878 4.94721 15.4993 4.77271C16.0937 3.84261 16.1634 2.68779 15.684 1.71193C15.0875 0.497702 13.8116 -0.163899 12.4499 0.0349582ZM13.2324 1.26516C14.4484 1.46117 15.1193 2.79891 14.5593 3.91079C14.4518 4.12423 14.3021 4.29508 13.6768 4.91793L12.9238 5.66807L11.6235 4.36876L10.3232 3.0695L11.0939 2.30681C11.926 1.48325 12.0607 1.3881 12.5411 1.28409C12.851 1.21698 12.9215 1.21506 13.2324 1.26516ZM8.37396 10.2123L4.70989 13.8714L3.06825 14.2229C2.16536 14.4162 1.42276 14.5704 1.41802 14.5657C1.41328 14.5609 1.56837 13.8168 1.76265 12.9121L2.11592 11.2671L5.77628 7.61174L9.43669 3.95631L10.7374 5.25477L12.0381 6.55323L8.37396 10.2123Z"
                            fill="white"
                          />
                        </svg>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="top">
                  <Formik
                    initialValues={userData}
                    enableReinitialize
                    validationSchema={profileUpdateSchema}
                    onSubmit={(values) => {
                      userProfile(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form>
                        <div className="login-input mt-3">
                          <div className="comman-grey">
                            <p>Email</p>
                          </div>
                          <input
                            type="text"
                            name="email"
                            className="input-box mt-1"
                            placeholder="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                        </div>
                        <div className="text-danger mt-2 mb-3">
                          {errors.email && touched.email && errors.email}
                        </div>
                        <div className="login-input mt-3">
                          <div className="comman-grey">
                            <p>Full Name</p>
                          </div>
                          <input
                            type="text"
                            name="full_name"
                            className="input-box mt-1"
                            placeholder="full name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.full_name}
                          />
                        </div>
                        <div className="text-danger mt-2 mb-3">
                          {errors.full_name &&
                            touched.full_name &&
                            errors.full_name}
                        </div>
                        <div className="login-input mt-3">
                          <div className="comman-grey">
                            <p>Mobile Number</p>
                          </div>
                          <input
                            type="text"
                            name="mobile_number"
                            className="input-box mt-1"
                            placeholder="mobile number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobile_number}
                          />
                        </div>
                        <div className="text-danger mt-2 mb-3">
                          {errors.mobile_number &&
                            touched.mobile_number &&
                            errors.mobile_number}
                        </div>
                        <div className="mt-3">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          )}
          {activeTab === 1 && (
            <div>
              <div className="payment-info m-4">
                <div className="comman-midium-blue">
                  <p>Change Password</p>
                </div>
                <Formik
                  initialValues={initialValue}
                  validationSchema={changePasswordSchema}
                  onSubmit={(values) => {
                    changePassword(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form>
                      <div className="login-input mt-3">
                        <div className="comman-grey">
                          <p>Current Password</p>
                        </div>
                        <input
                          type="text"
                          name="currentPassword"
                          className="input-box mt-1"
                          placeholder="Current Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.currentPassword}
                        />
                      </div>
                      <div className="text-danger mt-2 mb-3">
                        {errors.currentPassword &&
                          touched.currentPassword &&
                          errors.currentPassword}
                      </div>
                      <div className="login-input mt-3">
                        <div className="comman-grey">
                          <p>New Password</p>
                        </div>
                        <input
                          type="text"
                          name="newPassword"
                          className="input-box mt-1"
                          placeholder="New Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                        />
                      </div>
                      <div className="text-danger mt-2 mb-3">
                        {errors.newPassword &&
                          touched.newPassword &&
                          errors.newPassword}
                      </div>
                      <div className="login-input mt-3">
                        <div className="comman-grey">
                          <p>Confirm New Password</p>
                        </div>
                        <input
                          type="text"
                          name="confirmNewPassword"
                          className="input-box mt-1"
                          placeholder="Confirm New Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmNewPassword}
                        />
                      </div>
                      <div className="text-danger mt-2 mb-3">
                        {errors.confirmNewPassword &&
                          touched.confirmNewPassword &&
                          errors.confirmNewPassword}
                      </div>
                      <div className="mt-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Update Password
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Profile;
