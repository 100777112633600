/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import pageNotFound from '../../assets/404.svg'
import './PageNotFound.css'
import { NavLink } from 'react-router-dom'
import { ROUTES } from '../../config/navigation'

const PageNotFound = () => {
    return (

        <div className="text-center forget-password-main">
            <div className="fpm_inner_block container-fluid">
                <img
                    src={pageNotFound}
                    alt="forget-password"
                    className="image_404"
                />

            </div>
            <NavLink to={ROUTES.UNIT_DETAILS}> Go Back</NavLink>
        </div>
    )
}

export default PageNotFound