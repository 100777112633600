/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getOneBookingAPI } from "../../service/BookingService";
import { useSelector } from "react-redux";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const OneBooking = ({ id, isApiCall, setIsOneBooking, setIsApiCall }) => {
  // const id = location?.state?.id;
  const [bookingData, setBookingData] = useState({});
  const { profileData } = useSelector((state) => state.profileReducer);

  const getBookingDetails = async () => {
    if (isApiCall) {
      try {
        const res = await getOneBookingAPI(profileData.token, id);
        setBookingData(res?.data?.result);
      } catch (error) {
        ErrorAlert({ title: error?.response?.data?.error });
      }
    }
  };

  const goBackHandler = () => {
    setIsOneBooking({ id: 0, view: false });
    setIsApiCall(false);
  };

  console.log("bookingData", bookingData);
  useEffect(() => {
    getBookingDetails();
  }, []);
  return (
    <div>
      <div className="payment-info m-4">
        <div className="comman-midium-blue d-flex justify-content-between w-100">
          <div className="d-flex justify-content-start w-100  ">
          <div className="cp" onClick={() => goBackHandler()}>
            <ArrowBackIosIcon />
          </div>
          <p>Booking Details</p>
          </div>
          <p className="text-right w-100" style={{ textAlign: 'right' }}>Status: {bookingData.status}</p>
        </div>
      </div>
      <div className="main-box d-flex">
        <div className="payment-info m-4 w-100">
          <div className="comman-small-blue">
            <p>Unit Details</p>
          </div>
          <div className="user-info d-flex flex-column">
            <div className="d-flex w-100 my-1"><strong className="w-50">Unit No:</strong> <p>{bookingData?.flatDetails?.unit_no}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Price Per Day:</strong> <p>{bookingData?.price_per_day}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Cleaning Fees:</strong> <p>{bookingData?.cleaning_fees}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Start Date:</strong> <p>{bookingData?.start_date}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">End Date:</strong> <p>{bookingData?.end_date}</p></div>
          </div>
        </div>
        <div className="payment-info m-4 w-100">
          <div className="comman-small-blue">
            <p>User Details</p>
          </div>
          <div className="user-info">
          <div className="d-flex w-100 my-1"><strong className="w-50">Full Name:</strong> <p>{bookingData?.full_name}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Email:</strong> <p>{bookingData?.email}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Mobile Number:</strong> <p>{bookingData?.number}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Id Proof:</strong>{" "} <p><img
              src={bookingData?.id_proof}
              alt="id_proof"
              width={50}
              height={50}
            />{" "}</p> </div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Reason to Stay:</strong> <p>{bookingData?.reason}</p></div>
          </div>
        </div>
        <div className="payment-info m-4 w-100">
          <div className="comman-small-blue">
            <p>Payment Details</p>
          </div>
          <div className="user-info">
            <div className="d-flex w-100 my-1"><strong className="w-50">User Reference No:</strong> <p>{bookingData?.userXRefNum}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Admin Reference No:</strong>{" "}
            <p>{bookingData?.adminXRefNum}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Deposit Reference No:</strong>{" "}
            <p>{bookingData?.depositXRefNum}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Amount Paid:</strong> <p>{bookingData?.amount}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Deposit Paid:</strong> <p>{bookingData?.deposit}</p></div>
            <div className="d-flex w-100 my-1"><strong className="w-50">Deposit Refunded:</strong> <p>{bookingData?.refundDeposit}</p></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneBooking;
