import React from "react";

const Image = ({ src, index, handleDragStart, handleDragOver, handleDrop }) => {
  return (
    <img
      src={src}
      alt={`${index}`}
      draggable
      onDragStart={(e) => handleDragStart(e, index)}
      onDragOver={(e) => handleDragOver(e)}
      onDrop={(e) => handleDrop(e, index)}
      style={{ width: "100px", marginRight: "10px", cursor: "grab" }}
    />
  );
};

export default Image;
