import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DetailsIcon from "@mui/icons-material/Details";
import EngineeringIcon from "@mui/icons-material/Engineering";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CollectionsIcon from "@mui/icons-material/Collections";
import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import HouseboatIcon from "@mui/icons-material/Houseboat";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import "./sidebar.css";
const drawerWidth = 240;

export default function Sidebar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = [
    { title: "Users", link: "users", icon: <PeopleAltIcon /> },
    { title: "Unit Details", link: "unit-details", icon: <DetailsIcon /> },
    { title: "Amenities", link: "amenities", icon: <AppRegistrationIcon /> },
    { title: "Units", link: "units", icon: <ApartmentIcon /> },
    { title: "Maintenance", link: "maintenance", icon: <EngineeringIcon /> },
    { title: "Minimum Stay", link: "minimum-stay", icon: <HouseboatIcon /> },
    { title: "Blogs", link: "blogs", icon: <NewspaperIcon /> },
    { title: "Appartment", link: "appartment", icon: <NewspaperIcon /> },
    { title: "Testimonials", link: "testimonials", icon: <RateReviewIcon /> },
    { title: "Gallery", link: "gallery", icon: <CollectionsIcon /> },
    {
      title: "Gallery Types",
      link: "gallery-type",
      icon: <CollectionsBookmarkIcon />,
    },
    { title: "About Us", link: "about-us", icon: <InfoIcon /> },
    { title: "Contact Us", link: "contect-us", icon: <ContactPhoneIcon /> },
    { title: "Bookings", link: "bookings", icon: <LocalLibraryIcon /> },
    { title: "Chatbot", link: "chatbot", icon: <QuestionAnswerIcon /> },
    { title: "Leases", link: "lease-booking", icon: <DetailsIcon /> },
    { title: "Leases Unit", link: "lease-unit", icon: <DetailsIcon /> },
  ];
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const Logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const drawer = (
    <div>
      <Toolbar>
        <div
          className={`user-panel d-flex align-items-center cp ${
            location.pathname === "/profile" ? "profileActive" : ""
          }`}
          onClick={() => {
            navigate("/profile");
          }}
        >
          <div className="image">
            {userData && userData?.profile_pic ? (
              <img src={userData?.profile_pic} alt="profile_pic" />
            ) : (
              <AccountCircleIcon />
            )}
          </div>
          <div className="info p-0">
            <p className="d-block mt-2 ">
              {userData?.full_name || "Admin Name"}
            </p>
          </div>
        </div>
      </Toolbar>
      <Divider />
      <nav className="navigation_block">
        <List>
          {menuItems.map((ele, index) => {
            return (
              <NavLink
                key={index}
                to={ele.link}
                className={(navData) => {
                  return navData.isActive ? "nav-link active" : "nav-link";
                }}
                onClick={() => {
                  setMobileOpen(!mobileOpen);
                }}
              >
                <ListItem key={1} disablePadding className="navMenu">
                  <ListItemButton>
                    <ListItemIcon
                      className={
                        `/` + ele.link === location.pathname ? "activeIcon" : ""
                      }
                    >
                      {ele.icon}
                    </ListItemIcon>
                    <ListItemText primary={ele.title} />
                  </ListItemButton>
                </ListItem>
              </NavLink>
            );
          })}
        </List>
        <List>
          <ListItem key={1} disablePadding className="navMenu logout">
            <ListItemButton onClick={() => Logout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar className="header-panel">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Panel
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
