const inititalState = { Details: null, id: null, isEdit: false };
const unitDetailsReducer = (state = inititalState, action) => {
  if (action.type === "SETUNITDETAILS") {
    return { ...state, Details: action.payload };
  }
  if (action.type === "SETID") {
    return { ...state, id: action.payload };
  }
  if (action.type === "SETISEDIT") {
    return { ...state, isEdit: action.payload };
  }
  return state;
};
export default unitDetailsReducer;
