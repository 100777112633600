/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  deleteContectUsApi,
  getContectUsApi,
} from "../../service/ContectUsService";
import Actions from "../../common/ActionsButtons/Actions";
import CustomTable from "../../common/CustomTable/CustomTable";
import Modal from "react-responsive-modal";
import { useSelector } from "react-redux";
import ViewContectUs from "./ViewContectUs";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";
import Swal from "sweetalert2";
const ContectUs = () => {
  const [contectUsList, setContectUsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [viewOpen, setViewOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAllContectUs = async () => {
    setIsLoading(true);
    try {
      const res = await getContectUsApi(profileData.token);
      setContectUsList(res?.data?.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllContectUs();
  }, []);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Number",
      selector: (row) => row.number,
      sortable: true,
      reorder: true,
    },
    {
      id: 6,
      name: "Reason",
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: "Actions",
      selector: (row) => (
        <Actions
          row={row}
          viewopenModal={viewopenModal}
          deleteRecord={deleteRecord}
          hiddenEdit="EditModel"
        />
      ),
      reorder: true,
    },
  ];

  const viewopenModal = () => {
    setViewOpen(true);
  };
  const viewcloseModal = () => {
    setViewOpen(false);
  };

  const deleteRecord = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteContectUsApi(id, profileData.token)
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: res?.data?.msg,
              icon: "success",
            });
            getAllContectUs();
          })
          .catch((error) => {
            ErrorAlert({ title: error?.response?.data?.error });
          });
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-wrapper">
          <div className="m-4">
            {contectUsList && (
              <CustomTable
                columns={columns}
                data={contectUsList}
                setSearchText={setSearchText}
                searchText={searchText}
                hideModel="add-model"
              />
            )}
          </div>
          <Modal open={viewOpen} onClose={viewcloseModal} center>
            <h2 className="ms-5 me-5">Contact Us View</h2>
            <ViewContectUs closeModal={viewcloseModal} />
          </Modal>
        </div>
      )}
    </>
  );
};

export default ContectUs;
