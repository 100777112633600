/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PayDepositAPI, getBookingApi } from "../../service/BookingService";
import CustomTable from "../../common/CustomTable/CustomTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button } from "react-bootstrap";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import Loader from "../../common/loader/loader";
import Modal from "react-responsive-modal";
import { Formik } from "formik";
import moment from "moment";

const ApprovedBooking = (props) => {
  const [bookingList, setBookingList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { profileData } = useSelector((state) => state.profileReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [xRefNumData, setXRefNumData] = useState({
    id: null,
    xRefNumData: "",
    amount: 0,
  });

  const getAllBooking = async () => {
    setIsLoading(true);
    try {
      const res = await getBookingApi(profileData.token, "Approved");
      setBookingList(res?.data?.result);
    } catch (error) {
      ErrorAlert({ title: error?.response?.data?.error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBooking();
  }, []);

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row, idx) => idx + 1,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Name",
      selector: (row) => row.full_name,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Number",
      selector: (row) => row.number,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("DD/MM/YYYY"),
      sortable: true,
      reorder: true,
    },
    {
      id: 5,
      name: "End Date",
      selector: (row) => moment(row.end_date).format("DD/MM/YYYY"),
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 6,
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 7,
      name: "Deposit",
      selector: (row) => row.deposit,
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 8,
      name: "Reason",
      selector: (row) => row.reason,
      sortable: true,
      reorder: true,
    },
    {
      id: 9,
      name: "view",
      selector: (row) => (
        <div>
          <p
            className="m-1 cp viewModel"
            onClick={() => {
              props.setIsOneBooking({ id: row.id, view: true });
              props.setIsApiCall(true);
            }}
          >
            <VisibilityIcon />
          </p>
        </div>
      ),
      sortable: true,
      right: "true",
      reorder: true,
    },
    {
      id: 10,
      name: "",
      selector: (row) => (
        <Button variant="primary" onClick={() => OpenDepositModel(row)}>
          Pay Deposit
        </Button>
      ),
    },
  ];

  const onFinish = (values) => {
    setIsLoading(true);
    DepositClose();

    values.amount = Number(values.amount);
    PayDepositAPI(values, profileData.token, values.id)
      .then((res) => {
        SuccessAlert({ title: res?.data?.msg });
        getAllBooking();
        setIsLoading(false);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        setIsLoading(false);
      });
  };

  const DepositClose = () => {
    setOpen(false);
  };

  const OpenDepositModel = (row) => {
    setXRefNumData({
      id: row.id,
      xRefNum: row.adminXRefNum,
      amount: row.deposit,
    });
    setOpen(true);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="m-4">
          {bookingList && (
            <CustomTable
              columns={columns}
              data={bookingList}
              setSearchText={setSearchText}
              searchText={searchText}
              hideModel="add-model"
            />
          )}
        </div>
      )}
      <Modal open={open} onClose={DepositClose} center>
        <h2 className="ms-5">{`Pay Deposit`}</h2>
        <Formik
          initialValues={xRefNumData}
          enableReinitialize
          onSubmit={(values) => {
            onFinish(values);
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <div className="d-flex ms-5">
              <form className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="comman-grey">
                      <p>Amout</p>
                    </div>
                    <input
                      type="text"
                      className="input-box mt-1"
                      placeholder="Amout"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Pay
                  </button>
                  <button
                    type="button"
                    onClick={DepositClose}
                    className="btn btn-danger ms-3"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ApprovedBooking;
