/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  getSingleChatbotApi,
  getSingleChatbotDropdownApi,
  putChatbotApi,
} from "../../service/ChatbotService";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ErrorAlert, SuccessAlert } from "../../common/sweetalert/sweetalert";
import { addQuestionSchema } from "../../schema/chatbotSchema";

const EditChatbot = ({ closeModal, getAllChatbot }) => {
  const [relatedQuestionsList, setRelatedQuestionsList] = useState([]);
  const [relatedQuestionsIdArray, setRelatedQuestionsIdArray] = useState([]);
  const [details, setDetails] = useState({
    question: "",
    answer: "",
    relatedQuestions: [],
    link: "",
    at_display: true,
  });

  useEffect(() => {
    getSingleChatbotDropdownApi(profileData.token).then((res) => {
      setRelatedQuestionsList(res.data.result);
    });
  }, []);

  const { profileData } = useSelector((state) => state.profileReducer);
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const onFinish = async (values) => {
    putChatbotApi(
      id,
      { ...values, relatedQuestions: relatedQuestionsIdArray },
      profileData.token
    )
      .then((res) => {
        SuccessAlert({ title: res?.data?.msg });
        getAllChatbot();
        closeModal();
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };

  const getSingleChatData = () => {
    getSingleChatbotApi(id, profileData.token)
      .then((res) => {
        const relatedQuestionData =
          res.data.result?.relatedQuestionList?.length > 0
            ? res.data.result?.relatedQuestionList?.map((item) => ({
                value: item.id,
                label: item.question,
              }))
            : [];
        const idArray =
          res.data.result?.relatedQuestionList?.length > 0
            ? res.data.result?.relatedQuestionList?.map((item) => item.id)
            : [];
        const { relatedQuestionList, ...resList } = res.data.result;
        setRelatedQuestionsIdArray(idArray);
        setDetails({ ...resList, relatedQuestions: relatedQuestionData });
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
      });
  };
  useEffect(() => {
    getSingleChatData();
  }, []);

  return (
    <Formik
      initialValues={details}
      enableReinitialize
      validationSchema={addQuestionSchema}
      onSubmit={(values) => {
        onFinish(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="d-flex ms-5">
          <form className="row">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Question</p>
                </div>
                <input
                  type="text"
                  name="question"
                  className="input-box mt-1"
                  placeholder="Question"
                  onChange={handleChange}
                  value={values.question}
                />
                {errors.question && touched.question && (
                  <p className="text-danger mt-2 mb-3">{errors.question}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Answer</p>
                </div>
                <input
                  type="text"
                  className="input-box mt-1"
                  placeholder="Answer"
                  name="answer"
                  onChange={handleChange}
                  value={values.answer}
                />
                {errors.answer && touched.answer && (
                  <p className="text-danger mt-2 mb-3">{errors.answer}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Related Questions</p>
                </div>
                <Select
                  isMulti
                  name="relatedQuestions"
                  options={relatedQuestionsList?.map((question) => ({
                    value: question.id,
                    label: question.question,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={values.relatedQuestions}
                  onChange={(selectedOptions) => {
                    console.log("value ", values.relatedQuestions);
                    console.log("selectedOptions", selectedOptions);
                    const selectedIds = selectedOptions.map(
                      (option) => option.value
                    );
                    setRelatedQuestionsIdArray(selectedIds);
                    setFieldValue("relatedQuestions", selectedOptions); // Set the selected IDs to formik field
                  }}
                />
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey">
                  <p>Link</p>
                </div>
                <input
                  type="text"
                  className="input-box mt-1"
                  placeholder="Link"
                  name="link"
                  onChange={handleChange}
                  value={values.link}
                />
                {errors.link && touched.link && (
                  <p className="text-danger mt-2 mb-3">{errors.link}</p>
                )}
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="form-group">
                <div className="comman-grey ">
                  <p>Show this question on Display ?</p>
                </div>
                <div className="input-type">
                  <label>
                    <Field
                      type="radio"
                      name="at_display"
                      checked={values.at_display === true}
                      onChange={() => setFieldValue("at_display", true)}
                    />
                    Yes
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="at_display"
                      checked={values.at_display === false}
                      onChange={() => setFieldValue("at_display", false)}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-danger ms-3"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};

export default EditChatbot;
