import axios from "axios";

export const getLeaseUnitApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/lease-unit`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": 0,
    },
  });
};

export const deleteLeaseUnitApi = async (id, token) => {
  return await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/lease-unit/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const postLeaseUnitApi = async (values, token) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}/lease-unit`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getLeaseUnitFlatIdApi = async (token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/flat/listing`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": 0,
    },
  });
};

export const getSingleLeaseUnitApi = async (id, token) => {
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/lease-unit/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": 0,
    },
  });
};

export const putLeaseUnitApi = async (id, values, token) => {
  return await axios.put(
    `${process.env.REACT_APP_BASE_URL}/lease-unit/${id}`,
    values,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
