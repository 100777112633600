/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSingleMinimumStayApi } from "../../service/MinimumStayService";
import { Button, Card } from "react-bootstrap";
import moment from "moment";
import { ErrorAlert } from "../../common/sweetalert/sweetalert";

const ViewMinimumStay = ({ closeModal }) => {
  const { id } = useSelector((state) => state.unitDetailsReducer);
  const { profileData } = useSelector((state) => state.profileReducer);
  const [list, setList] = useState({});
  const getSingleMinimumStay = () => {
    getSingleMinimumStayApi(id, profileData.token)
      .then((res) => {
        setList(res.data.result);
      })
      .catch((err) => {
        ErrorAlert({ title: err?.response?.data?.error });
        console.log("err", err);
      });
  };
  useEffect(() => {
    getSingleMinimumStay();
  }, []);
  return (
    <div>
      <Card style={{ width: "100%" }}>
        <Card.Body>
          <Card.Title>
            <strong>Start Date</strong> :{" "}
            {moment(list?.start_date).format("DD/MM/YYYY")}
          </Card.Title>
          <Card.Title>
            <strong>End Date</strong> :{" "}
            {moment(list?.end_date).format("DD/MM/YYYY")}
          </Card.Title>
          <Card.Title>
            <strong>No Of Days</strong> : {list?.no_of_days}
          </Card.Title>
          <Card.Title>
            <strong>1 Bed / 1 Bath</strong> : {list?.category_1}
          </Card.Title>
          <Card.Title>
            <strong>2 Bed / 2 Bath</strong> : {list?.category_2}
          </Card.Title>
          <Card.Title>
            <strong>3 Bed / 3 Bath</strong> : {list?.category_3}
          </Card.Title>
          <Card.Title>
            <strong>Deluxe 1 Bed / 2 Bath / Home Office</strong> :{" "}
            {list?.category_4}
          </Card.Title>
          <Card.Title>
            <strong>Deluxe 2 Bed / 3 Bath / Home Office</strong> :{" "}
            {list?.category_5}
          </Card.Title>
          <Button
            variant="primary"
            onClick={() => closeModal()}
            className="mt-3"
          >
            Cancel
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ViewMinimumStay;
